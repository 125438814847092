@keyframes appearBox {
    from {
        transform: translateY(100px);
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes dash {
    to {
      stroke-dashoffset: 0;
      fill: #AEAEAE;
    }
}

@keyframes opacity {
	0% {
		opacity: 0;
	}

	10% {
		opacity: 0.1;
	}

	20% {
		opacity: 0.2;
	}

	30% {
		opacity: 0.3;
	}

	40% {
		opacity: 0.4;
	}

	50% {
		opacity: 0.5;
	}

	60% {
		opacity: 0.6;
	}

	70% {
		opacity: 0.7;
	}

	80% {
		opacity: 0.8;
	}

	90% {
		opacity: 0.9;
	}

	100% {
		opacity: 1;
	}
}

@keyframes bounce {
	0% {
		transform: translateY(0);
	}

	10% {
		transform: translateY(2px);
	}

	20% {
		transform: translateY(4px);
	}

	30% {
		transform: translateY(6px);
	}

	40% {
		transform: translateY(8px);
	}

	50% {
		transform: translateY(10px);
	}

	60% {
		transform: translateY(12px);
	}

	70% {
		transform: translateY(14px);
	}

	80% {
		transform: translateY(16px);
	}

	90% {
		transform: translateY(18px);
	}

	100% {
		transform: translateY(20px);
	}
}