.life-1{
    margin-bottom: 80px;
    margin-top: 90px;
        
    @include rpmin(md){
        margin-top: 0;
    }
    .item{
        transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &-top{
        position: relative;
    }
    &__behind{
        &>*{
            @include family--secondary;
            color: rgba(223, 223, 223, 1);
            // font-size: 100px;
            @include title(100px);
            @include extra-bold;
        }
        
        transform: translateY(54%);
        display: none;
        @include rpmin(md){
            display: block;
        }
    }
    
    &__title{
        position: relative;
        z-index: 1;
        margin-top: 40px;

        @include rpmin(md){
            margin-top: 0;
        }
        &>*{
            @include family--secondary;
            color: #ED1D24;
            // font-size: 72px;
            @include title(72px);
            @include extra-bold;
        }
    }

    .wrapper{
        margin-top: 20px;

        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .navleft{
            display: inline-flex;
            width: 100%;
            height: 100%;
            
            @include rpmin(lg){
                display: inline-flex;
                width: 24%;
    
                position: sticky;
                top: 40px;
                height: 100%;
            }

            &>ul{
                display: flex;
                flex-direction: column;

                width: 100%;

                list-style-type: none;
                border: 1px solid #DFDFDF;
                padding: 11px 16px;
                
                &>li{
                    @include family--primary;
                    // font-size: 18px;
                    @include title(18px);
                    color: #191919;
                    @include extra-bold;
                    line-height: 40px;

                    & + li{
                        border-top: 1px solid #DFDFDF;
                    }

                    &.active{
                        color: #ED1D24;
                    }
                    a{
                        color: currentColor;
                        display: block;
                        transition: .3s all cubic-bezier(0.075, 0.82, 0.165, 1);

                        &:hover, &:focus{
                            color: #ED1D24;

                            transform: translateX(8px);
                        }
   

                    }
                }
            }
        }

        .life-1-content{
            flex: 1;
        }

        .content_ux{
            font-size: 16px;
            @include rpmin(lg){
                font-size: 18px;
            }
            img{
                display: block;
            }
        }
    }

    &-content{
        .name{
            display: flex;
            align-items: flex-end;

            &>*{
                color: #ED1D24;
                @include extra-bold;
                // font-size: 32px;
                @include title(32px);
            }

            &__more{
                flex: 1;
                text-align: right;
                margin-left: 16px;

                &>*{
                    display: inline-block;
                    font-size: 15px;
                    @include regular;
                    text-decoration: underline;
                    transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
                    &:hover{
                        transform: translateX(-10px);
                    }
                }
            }
        }

        .item{
            margin-top: 40px;

            &-content{
                flex: 1;

                display: flex;
                flex-direction: column;
            }

            &__thumb{
                position: relative;
                width: 100%;
                padding-top: 62.5%;
                height: 0;
                
                @include rpmin(md){
                    width: 33.33333%;
                    padding-top: 27%;
                }

                overflow: hidden;
                &>*{
                    transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
                }

                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                }
            }

            &__desc{
                font-size: 15px;
                line-height: 30px;
                @include regular;

                ul{
                    list-style-type: none;
                }
            }

            &__btn{
                margin-top: 16px;
            
                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    width: 100%;
                    max-width: 100%;
                    
                    @include regular;
                    font-size: 14px;
                    line-height: 32px;
                    color: #ED1D24;
                    border: 1px solid currentColor;
                    
                    transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);

                    @include rpmin(sm){
                        width: 200px;
                    }
                    @include rpmin(lg){
                        line-height: 40px;
                        width: 240px;
                    }
                    
                    &:hover, &:focus{
                        background-color: #ED1D24;
                        color: #FFF;
                    }
                }
            }

            &__name{
                margin-bottom: 12px;

                &>*{
                    color: #000;
                    @include bold;
                    font-size: 22px;
                    @include rpmin(lg){
                        font-size: 24px;
                    }
                }
            }

            &__time{
                padding-left:24px;
                position: relative;

                margin-bottom: 10px;

                &::after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 10px;
                    height: 0;
                    border-bottom: 2px solid #ED1D24;

                    transform: translateY(-50%);
                }
                &>*{
                    font-size: 12px;
                    @include family--primary;
                    @include regular;
                    color: #AFAFAF;
                }
            }

            &__sub{
                margin-top: 20px;
                &>*{
                    font-size: 18px;
                    color: #000;
                    @include bold;
                }
            }

            &__more{
                &>*{
                    font-size: 15px;
                    @include regular;
                    line-height: 23px;
    
                    color: #ED1D24;
                    text-decoration: underline;
                }
            }
        }

        .form-jobs{
            .group-input{
                display: flex;
                *{
                    transition: .3s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
                }
                flex-direction: column;
                @include rpmin(md){
                    flex-direction: row;
                }

                input{
                    flex: 1;

                    height: 40px;
                    border: 1px solid #000; 
                    color: #000;
                    padding: 10px 40px;

                    background-color: transparent;
                    border-radius: 5px;

                    // &:invalid{
                    //     color: #ED1D24;
                    // }
                }
                button[type="submit"]{
                    padding: 10px 40px;
                    margin-top: 10px;
                    background-color: #ED1D24;
                    color: #FFF;

                    svg{
                        path{
                            &[fill]:not([fill="none"]){
                                fill: #FFF;
                            }
                            &[stroke]:not([stroke="none"]){
                                stroke: #FFF;
                            }
                        }
                    }
                    
                    @include rpmin(md){
                        margin-top: 0px;
                        margin-left: 17px;
                        padding: 10px 20px;

                        background-color: transparent;
                        color: #ED1D24;

                        svg{
                            path{
                                &[fill]:not([fill="none"]){
                                    fill: #ED1D24;
                                }
                                &[stroke]:not([stroke="none"]){
                                    stroke: #ED1D24;
                                }
                            }
                        }
                    }

                    height: 40px;
                    border: 1px solid #ED1D24;
                    border-radius: 5px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg{
                        margin-right: 12px;
                    }

                    &:hover, &:focus{
                        background-color: #ED1D24 !important;
                        color: #FFF !important;

                        svg{
                            path{
                                &[fill]:not([fill="none"]){
                                    fill: #FFF !important;
                                }
                                &[stroke]:not([stroke="none"]){
                                    stroke: #FFF !important;
                                }
                            }

                            transform: rotate(45deg) !important;
                        }
                    }
                }
            }
        }

        &>*:not(:first-child){
            margin-top: 40px;
            @include rpmin(lg){
                margin-top: 60px;
            }
        }

        .post-top{
            .item{
                display: flex;
                align-items: stretch;
                gap: 30px;
                flex-direction: column;

                @include rpmin(md){
                    flex-direction: row;
                    
                    &-content{
                        display: flex;
                        flex-direction: column;
                        order: 0;
                        .item__desc{
                            flex: 1;
                        }
                    }
    
                    &__thumb{
                        order: 1;
                    }
                }
                
            }
        }

        .post-culture{
            .item{
                display: flex;
                align-items: stretch;
                gap: 30px;
                flex-direction: column;
                
                @include rpmin(md){
                    flex-direction: row;

                    &-content{
                        display: flex;
                        flex-direction: column;
                        order: 0;
                        .item__desc{
                            flex: 1;
                        }
                    }
    
                    &__thumb{
                        order: 1;
                        width: 100%;
                        padding-top: 62%;

                        @include rpmin(md){
                            width: 34.3%;
                            padding-top: 21.8%;
                        }
                    }
    
                    &:nth-of-type(2n + 1){
                        .item{
                            &-content{
                                order: 1;
                            }
                            &__thumb{
                                order: 0;
                            }
                        }
                    }
                }
                
                @include rpmin(lg){
                    gap: 50px;
                }
                
                &__btn{
                    margin-top: 40px;
                    display: flex;
                    justify-content: center;
                }

                &:not(:first-child){
                    margin-top: 40px;
                }

                &:hover{
                    .item__thumb{
                        &>*{
                            transform: scale(1.2);
                        }
                    }

                    .item__name{
                        &>*{
                            color: #ED1D24;
                        }
                    }
                }
            }
        }

        .post-work{
            .item{
                display: flex;
                align-items: stretch;
        
                gap: 20px;
                flex-direction: column;
                
                @include rpmin(md){
                    gap: 40px;
                    flex-direction: row;

                    &__desc{
                        flex: 1;
                        color: #AFAFAF;
                    }
    
                    &__btn{
                        margin-top: 10px;
                    }

                }

                &:hover{
                    .item__name{
                        &>*{
                            color: #ED1D24;
                        }
                    }

                    .item__thumb{
                        &>*{
                            transform: scale(1.2);
                        }
                    }
                }

            }
        }

        .post-jobs{
            .item{
                &__name{
                    width: 100%;
                    @include rpmin(lg){
                        width: 60%;
                    }
                }

                &__desc{
                    margin-top: 10px;
                    color: #808080;
                }
                &__more{
                    margin-top: 10px;
                }

                &:hover{
                    .item__name{
                        &>*{
                            color: #ED1D24;
                        }
                    }
                }

                &__btn{
                    margin-top: 40px;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .jobs-detail{
            .item{
                h3{
                    margin-bottom: 20px;
                }
                line-height: 1.6;
            }
        }
    }

    &.sub-detail {
        .post-culture{
            .item{
                gap: 34px;

                &__thumb{
                    width: 100%;
                    padding-top: 62%;
                    
                    @include rpmin(md){
                        width: 33.33333%;
                        padding-top: 30%;
                    }
                }

                &-content{
                    padding-top: 20px;
                }
            }
        }
    }
}