/*!
* source: https://getbootstrap.com/
* Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
* Copyright 2011-2021 The Bootstrap Authors
* Copyright 2011-2021 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap");
.container,
.container-left,
.container-right {
  width: 96vw;
  margin-left: auto;
  margin-right: auto; }

@media (min-width: 420px) and (min-width: 1080px) {
  .container-right {
    margin-right: auto;
    margin-left: initial; } }

@media (min-width: 576px) and (min-width: 1080px) {
  .container-right {
    margin-right: auto;
    margin-left: initial; } }

@media (min-width: 768px) and (min-width: 1080px) {
  .container-right {
    margin-right: auto;
    margin-left: initial; } }

@media (min-width: 1080px) and (min-width: 1080px) {
  .container-right {
    margin-right: auto;
    margin-left: initial; } }

@media (min-width: 1280px) {
  .container {
    width: 1170px; } }

@media (min-width: 1280px) and (min-width: 1080px) {
  .container-right {
    margin-right: auto;
    margin-left: initial; } }

@media (min-width: 1440px) and (min-width: 1080px) {
  .container-right {
    margin-right: auto;
    margin-left: initial; } }

.col {
  flex: 1 0 0%; }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
  width: calc(100% + (var(--bs-gutter-x) * 0.5 * 2)); }
  .row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }
  .row-cols-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }

@media (min-width: 420px) {
  .col-sx {
    flex: 1 0 0%; }
  .row-cols-sx-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sx-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sx-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-sx-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-sx-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-sx-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-sx-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-sx-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sx-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-sx-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sx-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sx-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-sx-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-sm-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-sm-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-sm-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-md-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-md-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-md-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1080px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-lg-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-lg-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-lg-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1280px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-xl-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-xl-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-xl-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1440px) {
  .col-yl {
    flex: 1 0 0%; }
  .row-cols-yl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-yl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-yl-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-yl-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-yl-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-yl-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-yl-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-yl-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-yl-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-yl-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-yl-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-yl-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-yl-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-none {
  width: 0;
  flex: 0 0 0; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

@media (min-width: 420px) {
  .order-sx-1 {
    order: 1; }
  .order-sx-2 {
    order: 2; }
  .order-sx-3 {
    order: 3; }
  .order-sx-4 {
    order: 4; }
  .order-sx-5 {
    order: 5; }
  .order-sx-6 {
    order: 6; }
  .order-sx-7 {
    order: 7; }
  .order-sx-8 {
    order: 8; }
  .order-sx-9 {
    order: 9; }
  .order-sx-10 {
    order: 10; } }

@media (min-width: 576px) {
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; } }

@media (min-width: 768px) {
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; } }

@media (min-width: 1080px) {
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; } }

@media (min-width: 1280px) {
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; } }

@media (min-width: 1440px) {
  .order-yl-1 {
    order: 1; }
  .order-yl-2 {
    order: 2; }
  .order-yl-3 {
    order: 3; }
  .order-yl-4 {
    order: 4; }
  .order-yl-5 {
    order: 5; }
  .order-yl-6 {
    order: 6; }
  .order-yl-7 {
    order: 7; }
  .order-yl-8 {
    order: 8; }
  .order-yl-9 {
    order: 9; }
  .order-yl-10 {
    order: 10; } }

.offset-0 {
  margin-left: 0%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.offset-l-0 {
  margin-left: 0%; }

.offset-l-1 {
  margin-left: 8.33333%; }

.offset-l-2 {
  margin-left: 16.66667%; }

.offset-l-3 {
  margin-left: 25%; }

.offset-l-4 {
  margin-left: 33.33333%; }

.offset-l-5 {
  margin-left: 41.66667%; }

.offset-l-6 {
  margin-left: 50%; }

.offset-l-7 {
  margin-left: 58.33333%; }

.offset-l-8 {
  margin-left: 66.66667%; }

.offset-l-9 {
  margin-left: 75%; }

.offset-l-10 {
  margin-left: 83.33333%; }

.offset-l-11 {
  margin-left: 91.66667%; }

.offset-r-0 {
  margin-right: 0%; }

.offset-r-1 {
  margin-right: 8.33333%; }

.offset-r-2 {
  margin-right: 16.66667%; }

.offset-r-3 {
  margin-right: 25%; }

.offset-r-4 {
  margin-right: 33.33333%; }

.offset-r-5 {
  margin-right: 41.66667%; }

.offset-r-6 {
  margin-right: 50%; }

.offset-r-7 {
  margin-right: 58.33333%; }

.offset-r-8 {
  margin-right: 66.66667%; }

.offset-r-9 {
  margin-right: 75%; }

.offset-r-10 {
  margin-right: 83.33333%; }

.offset-r-11 {
  margin-right: 91.66667%; }

.gx-0 {
  --bs-gutter-x: 0; }

.gy-0 {
  --bs-gutter-y: 0; }

.gx-10 {
  --bs-gutter-x: 10px; }

.gy-10 {
  --bs-gutter-y: 10px; }

.gx-12 {
  --bs-gutter-x: 12px; }

.gy-12 {
  --bs-gutter-y: 12px; }

.gx-16 {
  --bs-gutter-x: 16px; }

.gy-16 {
  --bs-gutter-y: 16px; }

.gx-20 {
  --bs-gutter-x: 20px; }

.gy-20 {
  --bs-gutter-y: 20px; }

.gx-30 {
  --bs-gutter-x: 30px; }

.gy-30 {
  --bs-gutter-y: 30px; }

.gx-40 {
  --bs-gutter-x: 40px; }

.gy-40 {
  --bs-gutter-y: 40px; }

.gx-62 {
  --bs-gutter-x: 62px; }

.gy-62 {
  --bs-gutter-y: 62px; }

.gx-100 {
  --bs-gutter-x: 100px; }

.gy-100 {
  --bs-gutter-y: 100px; }

@media (min-width: 420px) {
  .col-sx-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sx-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sx-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sx-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sx-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sx-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sx-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sx-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sx-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sx-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sx-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sx-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sx-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sx-0 > * {
    margin-left: 0%; }
  .offset-sx-1 > * {
    margin-left: 8.33333%; }
  .offset-sx-2 > * {
    margin-left: 16.66667%; }
  .offset-sx-3 > * {
    margin-left: 25%; }
  .offset-sx-4 > * {
    margin-left: 33.33333%; }
  .offset-sx-5 > * {
    margin-left: 41.66667%; }
  .offset-sx-6 > * {
    margin-left: 50%; }
  .offset-sx-7 > * {
    margin-left: 58.33333%; }
  .offset-sx-8 > * {
    margin-left: 66.66667%; }
  .offset-sx-9 > * {
    margin-left: 75%; }
  .offset-sx-10 > * {
    margin-left: 83.33333%; }
  .offset-sx-11 > * {
    margin-left: 91.66667%; }
  .gx-sx-0 {
    --bs-gutter-x: 0; }
  .gy-sx-0 {
    --bs-gutter-y: 0; }
  .gx-sx-10 {
    --bs-gutter-x: 10px; }
  .gy-sx-10 {
    --bs-gutter-y: 10px; }
  .gx-sx-12 {
    --bs-gutter-x: 12px; }
  .gy-sx-12 {
    --bs-gutter-y: 12px; }
  .gx-sx-16 {
    --bs-gutter-x: 16px; }
  .gy-sx-16 {
    --bs-gutter-y: 16px; }
  .gx-sx-20 {
    --bs-gutter-x: 20px; }
  .gy-sx-20 {
    --bs-gutter-y: 20px; }
  .gx-sx-30 {
    --bs-gutter-x: 30px; }
  .gy-sx-30 {
    --bs-gutter-y: 30px; }
  .gx-sx-40 {
    --bs-gutter-x: 40px; }
  .gy-sx-40 {
    --bs-gutter-y: 40px; }
  .gx-sx-62 {
    --bs-gutter-x: 62px; }
  .gy-sx-62 {
    --bs-gutter-y: 62px; }
  .gx-sx-100 {
    --bs-gutter-x: 100px; }
  .gy-sx-100 {
    --bs-gutter-y: 100px; } }

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 > * {
    margin-left: 0%; }
  .offset-sm-1 > * {
    margin-left: 8.33333%; }
  .offset-sm-2 > * {
    margin-left: 16.66667%; }
  .offset-sm-3 > * {
    margin-left: 25%; }
  .offset-sm-4 > * {
    margin-left: 33.33333%; }
  .offset-sm-5 > * {
    margin-left: 41.66667%; }
  .offset-sm-6 > * {
    margin-left: 50%; }
  .offset-sm-7 > * {
    margin-left: 58.33333%; }
  .offset-sm-8 > * {
    margin-left: 66.66667%; }
  .offset-sm-9 > * {
    margin-left: 75%; }
  .offset-sm-10 > * {
    margin-left: 83.33333%; }
  .offset-sm-11 > * {
    margin-left: 91.66667%; }
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .gx-sm-10 {
    --bs-gutter-x: 10px; }
  .gy-sm-10 {
    --bs-gutter-y: 10px; }
  .gx-sm-12 {
    --bs-gutter-x: 12px; }
  .gy-sm-12 {
    --bs-gutter-y: 12px; }
  .gx-sm-16 {
    --bs-gutter-x: 16px; }
  .gy-sm-16 {
    --bs-gutter-y: 16px; }
  .gx-sm-20 {
    --bs-gutter-x: 20px; }
  .gy-sm-20 {
    --bs-gutter-y: 20px; }
  .gx-sm-30 {
    --bs-gutter-x: 30px; }
  .gy-sm-30 {
    --bs-gutter-y: 30px; }
  .gx-sm-40 {
    --bs-gutter-x: 40px; }
  .gy-sm-40 {
    --bs-gutter-y: 40px; }
  .gx-sm-62 {
    --bs-gutter-x: 62px; }
  .gy-sm-62 {
    --bs-gutter-y: 62px; }
  .gx-sm-100 {
    --bs-gutter-x: 100px; }
  .gy-sm-100 {
    --bs-gutter-y: 100px; } }

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 > * {
    margin-left: 0%; }
  .offset-md-1 > * {
    margin-left: 8.33333%; }
  .offset-md-2 > * {
    margin-left: 16.66667%; }
  .offset-md-3 > * {
    margin-left: 25%; }
  .offset-md-4 > * {
    margin-left: 33.33333%; }
  .offset-md-5 > * {
    margin-left: 41.66667%; }
  .offset-md-6 > * {
    margin-left: 50%; }
  .offset-md-7 > * {
    margin-left: 58.33333%; }
  .offset-md-8 > * {
    margin-left: 66.66667%; }
  .offset-md-9 > * {
    margin-left: 75%; }
  .offset-md-10 > * {
    margin-left: 83.33333%; }
  .offset-md-11 > * {
    margin-left: 91.66667%; }
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .gx-md-10 {
    --bs-gutter-x: 10px; }
  .gy-md-10 {
    --bs-gutter-y: 10px; }
  .gx-md-12 {
    --bs-gutter-x: 12px; }
  .gy-md-12 {
    --bs-gutter-y: 12px; }
  .gx-md-16 {
    --bs-gutter-x: 16px; }
  .gy-md-16 {
    --bs-gutter-y: 16px; }
  .gx-md-20 {
    --bs-gutter-x: 20px; }
  .gy-md-20 {
    --bs-gutter-y: 20px; }
  .gx-md-30 {
    --bs-gutter-x: 30px; }
  .gy-md-30 {
    --bs-gutter-y: 30px; }
  .gx-md-40 {
    --bs-gutter-x: 40px; }
  .gy-md-40 {
    --bs-gutter-y: 40px; }
  .gx-md-62 {
    --bs-gutter-x: 62px; }
  .gy-md-62 {
    --bs-gutter-y: 62px; }
  .gx-md-100 {
    --bs-gutter-x: 100px; }
  .gy-md-100 {
    --bs-gutter-y: 100px; } }

@media (min-width: 1080px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 > * {
    margin-left: 0%; }
  .offset-lg-1 > * {
    margin-left: 8.33333%; }
  .offset-lg-2 > * {
    margin-left: 16.66667%; }
  .offset-lg-3 > * {
    margin-left: 25%; }
  .offset-lg-4 > * {
    margin-left: 33.33333%; }
  .offset-lg-5 > * {
    margin-left: 41.66667%; }
  .offset-lg-6 > * {
    margin-left: 50%; }
  .offset-lg-7 > * {
    margin-left: 58.33333%; }
  .offset-lg-8 > * {
    margin-left: 66.66667%; }
  .offset-lg-9 > * {
    margin-left: 75%; }
  .offset-lg-10 > * {
    margin-left: 83.33333%; }
  .offset-lg-11 > * {
    margin-left: 91.66667%; }
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .gx-lg-10 {
    --bs-gutter-x: 10px; }
  .gy-lg-10 {
    --bs-gutter-y: 10px; }
  .gx-lg-12 {
    --bs-gutter-x: 12px; }
  .gy-lg-12 {
    --bs-gutter-y: 12px; }
  .gx-lg-16 {
    --bs-gutter-x: 16px; }
  .gy-lg-16 {
    --bs-gutter-y: 16px; }
  .gx-lg-20 {
    --bs-gutter-x: 20px; }
  .gy-lg-20 {
    --bs-gutter-y: 20px; }
  .gx-lg-30 {
    --bs-gutter-x: 30px; }
  .gy-lg-30 {
    --bs-gutter-y: 30px; }
  .gx-lg-40 {
    --bs-gutter-x: 40px; }
  .gy-lg-40 {
    --bs-gutter-y: 40px; }
  .gx-lg-62 {
    --bs-gutter-x: 62px; }
  .gy-lg-62 {
    --bs-gutter-y: 62px; }
  .gx-lg-100 {
    --bs-gutter-x: 100px; }
  .gy-lg-100 {
    --bs-gutter-y: 100px; } }

@media (min-width: 1280px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 > * {
    margin-left: 0%; }
  .offset-xl-1 > * {
    margin-left: 8.33333%; }
  .offset-xl-2 > * {
    margin-left: 16.66667%; }
  .offset-xl-3 > * {
    margin-left: 25%; }
  .offset-xl-4 > * {
    margin-left: 33.33333%; }
  .offset-xl-5 > * {
    margin-left: 41.66667%; }
  .offset-xl-6 > * {
    margin-left: 50%; }
  .offset-xl-7 > * {
    margin-left: 58.33333%; }
  .offset-xl-8 > * {
    margin-left: 66.66667%; }
  .offset-xl-9 > * {
    margin-left: 75%; }
  .offset-xl-10 > * {
    margin-left: 83.33333%; }
  .offset-xl-11 > * {
    margin-left: 91.66667%; }
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .gx-xl-10 {
    --bs-gutter-x: 10px; }
  .gy-xl-10 {
    --bs-gutter-y: 10px; }
  .gx-xl-12 {
    --bs-gutter-x: 12px; }
  .gy-xl-12 {
    --bs-gutter-y: 12px; }
  .gx-xl-16 {
    --bs-gutter-x: 16px; }
  .gy-xl-16 {
    --bs-gutter-y: 16px; }
  .gx-xl-20 {
    --bs-gutter-x: 20px; }
  .gy-xl-20 {
    --bs-gutter-y: 20px; }
  .gx-xl-30 {
    --bs-gutter-x: 30px; }
  .gy-xl-30 {
    --bs-gutter-y: 30px; }
  .gx-xl-40 {
    --bs-gutter-x: 40px; }
  .gy-xl-40 {
    --bs-gutter-y: 40px; }
  .gx-xl-62 {
    --bs-gutter-x: 62px; }
  .gy-xl-62 {
    --bs-gutter-y: 62px; }
  .gx-xl-100 {
    --bs-gutter-x: 100px; }
  .gy-xl-100 {
    --bs-gutter-y: 100px; } }

@media (min-width: 1440px) {
  .col-yl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-yl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-yl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-yl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-yl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-yl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-yl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-yl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-yl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-yl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-yl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-yl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-yl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-yl-0 > * {
    margin-left: 0%; }
  .offset-yl-1 > * {
    margin-left: 8.33333%; }
  .offset-yl-2 > * {
    margin-left: 16.66667%; }
  .offset-yl-3 > * {
    margin-left: 25%; }
  .offset-yl-4 > * {
    margin-left: 33.33333%; }
  .offset-yl-5 > * {
    margin-left: 41.66667%; }
  .offset-yl-6 > * {
    margin-left: 50%; }
  .offset-yl-7 > * {
    margin-left: 58.33333%; }
  .offset-yl-8 > * {
    margin-left: 66.66667%; }
  .offset-yl-9 > * {
    margin-left: 75%; }
  .offset-yl-10 > * {
    margin-left: 83.33333%; }
  .offset-yl-11 > * {
    margin-left: 91.66667%; }
  .gx-yl-0 {
    --bs-gutter-x: 0; }
  .gy-yl-0 {
    --bs-gutter-y: 0; }
  .gx-yl-10 {
    --bs-gutter-x: 10px; }
  .gy-yl-10 {
    --bs-gutter-y: 10px; }
  .gx-yl-12 {
    --bs-gutter-x: 12px; }
  .gy-yl-12 {
    --bs-gutter-y: 12px; }
  .gx-yl-16 {
    --bs-gutter-x: 16px; }
  .gy-yl-16 {
    --bs-gutter-y: 16px; }
  .gx-yl-20 {
    --bs-gutter-x: 20px; }
  .gy-yl-20 {
    --bs-gutter-y: 20px; }
  .gx-yl-30 {
    --bs-gutter-x: 30px; }
  .gy-yl-30 {
    --bs-gutter-y: 30px; }
  .gx-yl-40 {
    --bs-gutter-x: 40px; }
  .gy-yl-40 {
    --bs-gutter-y: 40px; }
  .gx-yl-62 {
    --bs-gutter-x: 62px; }
  .gy-yl-62 {
    --bs-gutter-y: 62px; }
  .gx-yl-100 {
    --bs-gutter-x: 100px; }
  .gy-yl-100 {
    --bs-gutter-y: 100px; } }

/*# sourceMappingURL=bootstrap-grid.css.map */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

:root {
  --cl-w: #FFF;
  --cl-b: #000;
  --cl-gray: #AEAEAE;
  --cl-b1: #151515;
  --cl-b2: #1C1C1C;
  --cl-b3: #222222;
  --wContainer: 96vw; }
  @media (min-width: 420px) {
    :root {
      --wContainer: ; } }
  @media (min-width: 576px) {
    :root {
      --wContainer: ; } }
  @media (min-width: 768px) {
    :root {
      --wContainer: ; } }
  @media (min-width: 1080px) {
    :root {
      --wContainer: ; } }
  @media (min-width: 1280px) {
    :root {
      --wContainer: 1170px; } }
  @media (min-width: 1440px) {
    :root {
      --wContainer: ; } }

html, body {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased; }

body {
  color: #000;
  background-color: var(--cl-w);
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto; }
  body.hideScroll {
    max-height: 100vh;
    overflow: hidden; }

main {
  position: relative;
  height: auto;
  width: 100%; }

main,
#banner,
#bannerSub,
#breadcrumb {
  margin-left: auto;
  margin-right: auto; }

ol, ul {
  padding-left: 0rem; }

a {
  color: currentColor; }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

a[data-fancybox] img {
  cursor: zoom-in; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

.hidden {
  display: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

b,
strong {
  font-weight: bolder;
  font-weight: 700; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  text-decoration: none;
  background-color: transparent; }

pre,
code,
kbd,
samp {
  font-family: inherit;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  border-style: none;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

*:focus,
*:active {
  outline: none; }

.toast-msg {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 11111;
  list-style-type: none; }
  @media screen and (min-width: 1080px) {
    .toast-msg {
      bottom: 20px; } }
  .toast-msg li {
    display: block;
    background-color: #333;
    transform: translateX(calc(100% + 20px));
    transition: 0.6s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer; }
    .toast-msg li.success {
      background-color: #40f467; }
    .toast-msg li.error {
      background-color: #D81A1A; }
    .toast-msg li > p {
      color: #fff;
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-size: 16px;
      padding: 6px 8px; }
      @media screen and (min-width: 1080px) {
        .toast-msg li > p {
          padding: 8px 12px; } }
    .toast-msg li.active {
      transform: translateX(0); }
    .toast-msg li > * {
      user-select: none;
      pointer-events: none; }
  .toast-msg li + li {
    margin-top: 10px; }

.share-socials {
  margin-top: 30px; }
  @media screen and (min-width: 1080px) {
    .share-socials {
      margin-top: 60px; } }
  .share-socials h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #000;
    font-weight: 700; }
  .share-socials ul {
    margin-top: 24px;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap; }
    .share-socials ul li > a {
      display: inline-block; }
      .share-socials ul li > a > * {
        user-select: none;
        pointer-events: none; }
    .share-socials ul li + li {
      margin-left: 30px; }

@keyframes appearBox {
  from {
    transform: translateY(100px);
    opacity: 0; }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes dash {
  to {
    stroke-dashoffset: 0;
    fill: #AEAEAE; } }

@keyframes opacity {
  0% {
    opacity: 0; }
  10% {
    opacity: 0.1; }
  20% {
    opacity: 0.2; }
  30% {
    opacity: 0.3; }
  40% {
    opacity: 0.4; }
  50% {
    opacity: 0.5; }
  60% {
    opacity: 0.6; }
  70% {
    opacity: 0.7; }
  80% {
    opacity: 0.8; }
  90% {
    opacity: 0.9; }
  100% {
    opacity: 1; } }

@keyframes bounce {
  0% {
    transform: translateY(0); }
  10% {
    transform: translateY(2px); }
  20% {
    transform: translateY(4px); }
  30% {
    transform: translateY(6px); }
  40% {
    transform: translateY(8px); }
  50% {
    transform: translateY(10px); }
  60% {
    transform: translateY(12px); }
  70% {
    transform: translateY(14px); }
  80% {
    transform: translateY(16px); }
  90% {
    transform: translateY(18px); }
  100% {
    transform: translateY(20px); } }

.pagination {
  background-color: var(--bgSection, #FFF);
  --wh: 30px;
  --sText: 16px;
  --swArrow: 7px;
  --shArrow: 12px;
  --bgBtn: transparent;
  --text: #000;
  --bgBtnHover: #ED1D24;
  --textHover: #FFF;
  --bgArrow: transparent;
  --textArrow: #000000;
  --bgArrowHover: #ED1D24;
  --textArrowHover: #FFF;
  list-style-type: none; }
  @media screen and (min-width: 1080px) {
    .pagination {
      --wh: 30px;
      --sText: 16px;
      --swArrow: 6px;
      --shArrow: 10x; } }
  .pagination li {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    transition: .3s ease-in-out;
    font-family: "Open Sans", sans-serif;
    height: var(--wh);
    width: var(--wh);
    font-size: var(--sText);
    color: var(--text);
    background-color: var(--bgBtn);
    border: 1px solid var(--text); }
    .pagination li:not(.active):hover {
      color: var(--textHover);
      background-color: var(--bgBtnHover);
      border: 0px solid transparent; }
    .pagination li svg {
      width: var(--swArrow);
      height: var(--shArrow); }
    .pagination li.active {
      cursor: initial;
      color: var(--textHover);
      background-color: var(--bgBtnHover);
      border: 0px solid transparent; }
  .pagination li.next,
  .pagination li.prev {
    background-color: var(--bgArrow);
    width: var(--wh);
    height: var(--wh);
    transition: .3s ease-in-out; }
    .pagination li.next path[fill],
    .pagination li.prev path[fill] {
      fill: var(--textArrow); }
    .pagination li.next path[stroke],
    .pagination li.prev path[stroke] {
      stroke: var(--textArrow); }
    .pagination li.next:not(.btn-hidden):hover,
    .pagination li.prev:not(.btn-hidden):hover {
      background-color: var(--bgArrowHover); }
      .pagination li.next:not(.btn-hidden):hover path[fill],
      .pagination li.prev:not(.btn-hidden):hover path[fill] {
        fill: var(--textArrowHover); }
      .pagination li.next:not(.btn-hidden):hover path[stroke],
      .pagination li.prev:not(.btn-hidden):hover path[stroke] {
        stroke: var(--textArrowHover); }
    .pagination li.next.btn-hidden,
    .pagination li.prev.btn-hidden {
      display: none;
      cursor: initial; }
  .pagination li.prev.btn-hidden + li {
    margin-left: 0; }
  .pagination li.prev svg {
    transform: rotate(180deg); }
  .pagination li.dot {
    user-select: none;
    pointer-events: none;
    background-color: var(--bgBtn); }
  .pagination li + li {
    margin-left: 10px; }
    @media screen and (min-width: 1080px) {
      .pagination li + li {
        margin-left: 18px; } }

.pagination {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 40px; }
  .pagination li {
    font-weight: bold; }

.ov-hide {
  overflow: hidden; }

.m-auto {
  margin-left: auto;
  margin-right: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.thin {
  font-weight: 100 !important; }

.extra-light {
  font-weight: 200 !important; }

.light {
  font-weight: 300 !important; }

.regular {
  font-weight: 400 !important; }

.normal {
  font-weight: 400 !important; }

.medium {
  font-weight: 500 !important; }

.semi-bold {
  font-weight: 600 !important; }

.bold {
  font-weight: 700 !important; }

.extra-bold {
  font-weight: 800 !important; }

.ultra-bold {
  font-weight: 900; }

.m-c {
  margin-left: auto;
  margin-right: auto; }

.tc {
  text-align: center; }

.tl {
  text-align: left; }

.tr {
  text-align: right; }

.fx {
  display: flex; }

.fx-c {
  justify-content: center;
  align-items: center; }

.fx-wrap {
  flex-wrap: wrap; }

.fx-col {
  flex-direction: column; }

.fx-row {
  flex-direction: row; }

.jc-c {
  justify-content: center; }

.jc-sb {
  justify-content: space-between; }

.jc-fs {
  justify-content: flex-start; }

.jc-fe {
  justify-content: flex-end; }

.ai-c {
  align-items: center; }

.ai-s {
  align-items: stretch; }

.ai-sb {
  align-items: space-between; }

.ai-fs {
  align-items: flex-start; }

.ai-fe {
  align-items: flex-end; }

@media (min-width: 420px) {
  .tc-sx {
    text-align: center; }
  .tl-sx {
    text-align: left; }
  .tr-sx {
    text-align: right; }
  .jc-c-sx {
    justify-content: center; }
  .jc-sb-sx {
    justify-content: space-between; }
  .jc-fs-sx {
    justify-content: flex-start; }
  .jc-fe-sx {
    justify-content: flex-end; }
  .ai-c-sx {
    align-items: center; }
  .ai-s-sx {
    align-items: stretch; }
  .ai-sb-sx {
    align-items: space-between; }
  .ai-fs-sx {
    align-items: flex-start; }
  .ai-fe-sx {
    align-items: flex-end; }
  .fx-wrap-sx {
    flex-wrap: wrap; }
  .fx-col-sx {
    flex-direction: column; }
  .fx-row-sx {
    flex-direction: row; } }

@media (min-width: 576px) {
  .tc-sm {
    text-align: center; }
  .tl-sm {
    text-align: left; }
  .tr-sm {
    text-align: right; }
  .jc-c-sm {
    justify-content: center; }
  .jc-sb-sm {
    justify-content: space-between; }
  .jc-fs-sm {
    justify-content: flex-start; }
  .jc-fe-sm {
    justify-content: flex-end; }
  .ai-c-sm {
    align-items: center; }
  .ai-s-sm {
    align-items: stretch; }
  .ai-sb-sm {
    align-items: space-between; }
  .ai-fs-sm {
    align-items: flex-start; }
  .ai-fe-sm {
    align-items: flex-end; }
  .fx-wrap-sm {
    flex-wrap: wrap; }
  .fx-col-sm {
    flex-direction: column; }
  .fx-row-sm {
    flex-direction: row; } }

@media (min-width: 768px) {
  .tc-md {
    text-align: center; }
  .tl-md {
    text-align: left; }
  .tr-md {
    text-align: right; }
  .jc-c-md {
    justify-content: center; }
  .jc-sb-md {
    justify-content: space-between; }
  .jc-fs-md {
    justify-content: flex-start; }
  .jc-fe-md {
    justify-content: flex-end; }
  .ai-c-md {
    align-items: center; }
  .ai-s-md {
    align-items: stretch; }
  .ai-sb-md {
    align-items: space-between; }
  .ai-fs-md {
    align-items: flex-start; }
  .ai-fe-md {
    align-items: flex-end; }
  .fx-wrap-md {
    flex-wrap: wrap; }
  .fx-col-md {
    flex-direction: column; }
  .fx-row-md {
    flex-direction: row; } }

@media (min-width: 1080px) {
  .tc-lg {
    text-align: center; }
  .tl-lg {
    text-align: left; }
  .tr-lg {
    text-align: right; }
  .jc-c-lg {
    justify-content: center; }
  .jc-sb-lg {
    justify-content: space-between; }
  .jc-fs-lg {
    justify-content: flex-start; }
  .jc-fe-lg {
    justify-content: flex-end; }
  .ai-c-lg {
    align-items: center; }
  .ai-s-lg {
    align-items: stretch; }
  .ai-sb-lg {
    align-items: space-between; }
  .ai-fs-lg {
    align-items: flex-start; }
  .ai-fe-lg {
    align-items: flex-end; }
  .fx-wrap-lg {
    flex-wrap: wrap; }
  .fx-col-lg {
    flex-direction: column; }
  .fx-row-lg {
    flex-direction: row; } }

@media (min-width: 1280px) {
  .tc-xl {
    text-align: center; }
  .tl-xl {
    text-align: left; }
  .tr-xl {
    text-align: right; }
  .jc-c-xl {
    justify-content: center; }
  .jc-sb-xl {
    justify-content: space-between; }
  .jc-fs-xl {
    justify-content: flex-start; }
  .jc-fe-xl {
    justify-content: flex-end; }
  .ai-c-xl {
    align-items: center; }
  .ai-s-xl {
    align-items: stretch; }
  .ai-sb-xl {
    align-items: space-between; }
  .ai-fs-xl {
    align-items: flex-start; }
  .ai-fe-xl {
    align-items: flex-end; }
  .fx-wrap-xl {
    flex-wrap: wrap; }
  .fx-col-xl {
    flex-direction: column; }
  .fx-row-xl {
    flex-direction: row; } }

@media (min-width: 1440px) {
  .tc-yl {
    text-align: center; }
  .tl-yl {
    text-align: left; }
  .tr-yl {
    text-align: right; }
  .jc-c-yl {
    justify-content: center; }
  .jc-sb-yl {
    justify-content: space-between; }
  .jc-fs-yl {
    justify-content: flex-start; }
  .jc-fe-yl {
    justify-content: flex-end; }
  .ai-c-yl {
    align-items: center; }
  .ai-s-yl {
    align-items: stretch; }
  .ai-sb-yl {
    align-items: space-between; }
  .ai-fs-yl {
    align-items: flex-start; }
  .ai-fe-yl {
    align-items: flex-end; }
  .fx-wrap-yl {
    flex-wrap: wrap; }
  .fx-col-yl {
    flex-direction: column; }
  .fx-row-yl {
    flex-direction: row; } }

.uppercase {
  text-transform: uppercase; }

.bold {
  font-weight: bold; }

.italic {
  font-style: italic; }

.d-none {
  display: none; }

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.animate__bounce {
  animation-name: bounce;
  animation-duration: 1s;
  transform-origin: center bottom; }

.scrollgsap {
  position: relative;
  transition: top .5s ease,opacity .1s ease;
  top: 150px;
  opacity: 0; }
  .scrollgsap.gsap-loaded {
    top: 0;
    opacity: 1; }

.of-scroll {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .of-scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 3px; }
  .of-scroll::-webkit-scrollbar-track {
    background: #EAEAEA; }
  .of-scroll::-webkit-scrollbar-thumb {
    background: var(--color-main); }
  .of-scroll::-webkit-scrollbar-thumb:hover {
    background: #5D5D5D; }

.appear-box {
  animation: appearBox .4s linear; }

.plc-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-7 {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-8 {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-9 {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-10 {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.cl-w {
  color: #FFF; }

.cl-b {
  color: #000; }

header {
  background: #191919;
  height: 90px;
  z-index: 12; }
  @media screen and (max-width: 1279px) {
    header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%; }
      header .mobile-toggle {
        position: absolute;
        bottom: 25px;
        right: 20px;
        display: block;
        width: 40px;
        height: 30px; }
        header .mobile-toggle .line {
          position: absolute;
          top: 0;
          display: block;
          content: '';
          width: 20px;
          right: 10px;
          height: 3px;
          background: #ED1D24;
          transition: all 0.25s ease-in-out; }
          header .mobile-toggle .line:nth-of-type(2) {
            top: 8px; }
          header .mobile-toggle .line:nth-of-type(3) {
            top: 16px; }
        header .mobile-toggle.showed .line:first-child {
          opacity: 0; }
        header .mobile-toggle.showed .line:nth-of-type(2) {
          transform: rotate(45deg) translate(6px, 0); }
        header .mobile-toggle.showed .line:nth-of-type(3) {
          transform: rotate(-45deg) translate(5px, 0); }
        header .mobile-toggle.showed ~ .content_menu {
          left: 0;
          visibility: visible; } }
  @media screen and (min-width: 1280px) {
    header {
      position: relative; }
      header .mobile-toggle {
        display: none; } }
  header nav {
    display: flex;
    height: 100%; }
  header .logo {
    padding: 12px 0;
    display: flex;
    align-items: center;
    height: 100%;
    flex: 0 0 160px;
    width: 160px; }
    @media screen and (min-width: 1280px) {
      header .logo {
        flex: 0 0 224px;
        width: 224px; } }
    header .logo a {
      display: inline-block; }
      header .logo a img {
        display: block;
        height: 100%; }
  @media screen and (min-width: 1280px) {
    header .content_menu {
      flex: 1;
      margin-left: 20px; }
      header .content_menu .list_menu {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: flex-end; }
        header .content_menu .list_menu li {
          position: relative;
          padding-bottom: 15px; }
          header .content_menu .list_menu li a {
            color: #fff;
            background: #353535;
            font-size: 14px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 60px;
            padding: 0 16px;
            font-weight: 700;
            text-transform: uppercase;
            white-space: nowrap; }
            header .content_menu .list_menu li a:hover {
              background: #ED1D24; }
          header .content_menu .list_menu li:hover .content_submenu {
            display: block; }
          header .content_menu .list_menu li .content_submenu {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            background: #191919;
            padding: 7px 15px; }
            header .content_menu .list_menu li .content_submenu a {
              color: #fff;
              font-size: 15px;
              padding: 12px 0;
              font-weight: 700;
              background-color: transparent;
              height: auto;
              text-transform: initial;
              white-space: nowrap; }
              header .content_menu .list_menu li .content_submenu a:hover {
                background-color: transparent;
                color: #ED1D24; }
            header .content_menu .list_menu li .content_submenu .name:not(:first-child) {
              border-top: #353535 solid 2px; }
        header .content_menu .list_menu li + li {
          margin-left: 3px; }
        header .content_menu .list_menu li.focus a {
          background: #ED1D24; }
        header .content_menu .list_menu li.wrap a {
          white-space: initial; }
        header .content_menu .list_menu li.product, header .content_menu .list_menu li.search {
          position: static; }
          header .content_menu .list_menu li.product .content_submenu, header .content_menu .list_menu li.search .content_submenu {
            width: 100vw; }
            header .content_menu .list_menu li.product .content_submenu .name a, header .content_menu .list_menu li.search .content_submenu .name a {
              color: #ED1D24;
              font-size: 15px;
              padding: 12px 0; }
            header .content_menu .list_menu li.product .content_submenu ul, header .content_menu .list_menu li.search .content_submenu ul {
              list-style-type: none; }
              header .content_menu .list_menu li.product .content_submenu ul li, header .content_menu .list_menu li.search .content_submenu ul li {
                padding-bottom: 0; }
              header .content_menu .list_menu li.product .content_submenu ul a, header .content_menu .list_menu li.search .content_submenu ul a {
                font-size: 13px;
                font-weight: 700;
                color: #DFDFDF;
                padding: 0; }
            header .content_menu .list_menu li.product .content_submenu .item > li, header .content_menu .list_menu li.search .content_submenu .item > li {
              padding: 10px 0;
              border-bottom: solid 1px #353535;
              position: relative; }
              header .content_menu .list_menu li.product .content_submenu .item > li.active .item-sub, header .content_menu .list_menu li.search .content_submenu .item > li.active .item-sub {
                margin-top: 15px; }
                header .content_menu .list_menu li.product .content_submenu .item > li.active .item-sub li, header .content_menu .list_menu li.product .content_submenu .item > li.active .item-sub li > a, header .content_menu .list_menu li.search .content_submenu .item > li.active .item-sub li, header .content_menu .list_menu li.search .content_submenu .item > li.active .item-sub li > a {
                  opacity: 1;
                  height: auto;
                  visibility: visible;
                  transform: scaleY(1); }
                header .content_menu .list_menu li.product .content_submenu .item > li.active .item-sub::before, header .content_menu .list_menu li.search .content_submenu .item > li.active .item-sub::before {
                  opacity: 0;
                  visibility: hidden; }
            header .content_menu .list_menu li.product .content_submenu .item-sub, header .content_menu .list_menu li.search .content_submenu .item-sub {
              margin-top: 0; }
              header .content_menu .list_menu li.product .content_submenu .item-sub li, header .content_menu .list_menu li.search .content_submenu .item-sub li {
                opacity: 0;
                height: 0;
                visibility: hidden;
                transform: scaleY(0);
                transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1); }
              header .content_menu .list_menu li.product .content_submenu .item-sub li > a, header .content_menu .list_menu li.search .content_submenu .item-sub li > a {
                font-weight: 400;
                padding: 4px 0;
                transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1);
                transform: scaleY(0);
                opacity: 0;
                height: 0;
                visibility: hidden; }
              header .content_menu .list_menu li.product .content_submenu .item-sub::after, header .content_menu .list_menu li.product .content_submenu .item-sub::before, header .content_menu .list_menu li.search .content_submenu .item-sub::after, header .content_menu .list_menu li.search .content_submenu .item-sub::before {
                content: "";
                position: absolute;
                top: 22px;
                right: 10px;
                width: 10px;
                background-color: red;
                border-bottom: 2px solid #808080; }
              header .content_menu .list_menu li.product .content_submenu .item-sub::before, header .content_menu .list_menu li.search .content_submenu .item-sub::before {
                content: "";
                position: absolute;
                top: 22px;
                right: 10px;
                width: 10px;
                background-color: red;
                border-bottom: 2px solid #808080;
                transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1);
                transform: rotate(-90deg); }
          header .content_menu .list_menu li.product .form-search, header .content_menu .list_menu li.search .form-search {
            width: 100%;
            background: #F2F2F2;
            padding: 0; }
            header .content_menu .list_menu li.product .form-search .group-input, header .content_menu .list_menu li.search .form-search .group-input {
              width: 100%;
              position: relative; }
              header .content_menu .list_menu li.product .form-search .group-input input[type="text"], header .content_menu .list_menu li.search .form-search .group-input input[type="text"] {
                width: 100%;
                height: 60px;
                font-weight: 400;
                color: #808080;
                background: none;
                outline: none;
                border: none;
                font-size: 16px; }
              header .content_menu .list_menu li.product .form-search .group-input button[type="submit"], header .content_menu .list_menu li.product .form-search .group-input input[type="submit"], header .content_menu .list_menu li.search .form-search .group-input button[type="submit"], header .content_menu .list_menu li.search .form-search .group-input input[type="submit"] {
                position: absolute;
                right: 0;
                top: 0;
                width: 112px;
                height: 60px;
                border: none;
                outline: none;
                background: #ED1D24;
                font-size: 16px;
                font-weight: 700;
                color: #fff; }
        header .content_menu .list_menu li.search .icon {
          display: none; } }
  @media screen and (max-width: 1279px) {
    header .content_menu {
      visibility: hidden;
      position: absolute;
      top: 90px;
      left: -100%;
      width: 100vw;
      height: calc(100vh - 90px);
      overflow-x: hidden;
      overflow-y: auto;
      transition: all 500ms;
      background: #353535; }
      header .content_menu .list_menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        list-style-type: none; }
        header .content_menu .list_menu > li {
          position: relative;
          width: 100%;
          padding: 0 2vw;
          border-bottom: solid 1px #484848; }
          header .content_menu .list_menu > li .content_submenu .banner_adv {
            display: none; }
          header .content_menu .list_menu > li .content_submenu > * {
            display: none; }
          header .content_menu .list_menu > li.active .content_submenu > * {
            display: block; }
          header .content_menu .list_menu > li.active .content_submenu::after {
            transform: rotate(90deg); }
          header .content_menu .list_menu > li .content_submenu {
            background: #191919;
            margin: 0 -2vw;
            padding: 10px 2vw; }
            header .content_menu .list_menu > li .content_submenu::after {
              content: "\203A";
              position: absolute;
              top: 0px;
              right: 2vw;
              font-size: 22px;
              line-height: 60px;
              color: #FFF;
              transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1); }
            header .content_menu .list_menu > li .content_submenu .name {
              margin: 10px 0;
              color: #ED1D24;
              font-size: 15px;
              border-bottom: none;
              font-weight: 700;
              padding: 12px 0; }
              header .content_menu .list_menu > li .content_submenu .name a {
                color: #ED1D24;
                font-size: 15px;
                padding: 12px 0; }
            header .content_menu .list_menu > li .content_submenu ul {
              list-style-type: none; }
              header .content_menu .list_menu > li .content_submenu ul li {
                padding-bottom: 0; }
              header .content_menu .list_menu > li .content_submenu ul a {
                font-size: 13px;
                font-weight: 700;
                color: #DFDFDF;
                padding: 0; }
            header .content_menu .list_menu > li .content_submenu .item > li {
              padding: 10px 10px;
              border-bottom: solid 1px #909090;
              position: relative;
              background-color: #353535; }
              header .content_menu .list_menu > li .content_submenu .item > li.active .item-sub {
                margin-top: 15px; }
                header .content_menu .list_menu > li .content_submenu .item > li.active .item-sub li, header .content_menu .list_menu > li .content_submenu .item > li.active .item-sub li > a {
                  opacity: 1;
                  height: auto;
                  visibility: visible;
                  transform: rotateX(0deg); }
                header .content_menu .list_menu > li .content_submenu .item > li.active .item-sub::before {
                  opacity: 0;
                  visibility: hidden; }
            header .content_menu .list_menu > li .content_submenu .item-sub {
              padding-left: 10px; }
              header .content_menu .list_menu > li .content_submenu .item-sub li, header .content_menu .list_menu > li .content_submenu .item-sub li > a {
                opacity: 0;
                height: 0;
                visibility: hidden;
                transform: rotateX(90deg);
                transition: .3s all linear; }
              header .content_menu .list_menu > li .content_submenu .item-sub li > a {
                font-weight: 400;
                padding: 4px 0; }
              header .content_menu .list_menu > li .content_submenu .item-sub::after, header .content_menu .list_menu > li .content_submenu .item-sub::before {
                content: "";
                position: absolute;
                top: 22px;
                right: 10px;
                width: 10px;
                border-bottom: 2px solid #808080; }
              header .content_menu .list_menu > li .content_submenu .item-sub::before {
                content: "";
                position: absolute;
                top: 22px;
                right: 10px;
                width: 10px;
                border-bottom: 2px solid #808080;
                transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1);
                transform: rotate(-90deg); }
          header .content_menu .list_menu > li > a {
            color: #fff;
            background: transparent;
            font-size: 14px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 60px;
            font-weight: 700;
            text-transform: uppercase;
            white-space: nowrap; }
        header .content_menu .list_menu li.search > a {
          display: none; }
        header .content_menu .list_menu li.search .content_submenu > * {
          display: block !important; }
        header .content_menu .list_menu li.search .content_submenu::after {
          content: none; }
        header .content_menu .list_menu li.search .form-search {
          background-color: #353535; }
          header .content_menu .list_menu li.search .form-search .group-input {
            display: flex; }
            header .content_menu .list_menu li.search .form-search .group-input input {
              line-height: 60px;
              text-align: center;
              order: 2;
              flex: 1;
              font-size: 14px;
              font-weight: 700;
              color: white;
              text-transform: uppercase;
              letter-spacing: 1px;
              background-color: transparent;
              outline: none;
              border: 1px solid #ed1d24; }
              header .content_menu .list_menu li.search .form-search .group-input input::-webkit-input-placeholder {
                /* Edge */
                color: white;
                text-transform: uppercase; }
              header .content_menu .list_menu li.search .form-search .group-input input:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: white;
                text-transform: uppercase; }
              header .content_menu .list_menu li.search .form-search .group-input input::placeholder {
                color: white;
                text-transform: uppercase; }
            header .content_menu .list_menu li.search .form-search .group-input button[type="submit"], header .content_menu .list_menu li.search .form-search .group-input input[type="submit"] {
              order: 1;
              line-height: 60px;
              width: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ed1d24;
              outline: none;
              border: none; }
              header .content_menu .list_menu li.search .form-search .group-input button[type="submit"] span, header .content_menu .list_menu li.search .form-search .group-input input[type="submit"] span {
                display: none; }
                header .content_menu .list_menu li.search .form-search .group-input button[type="submit"] span.icon, header .content_menu .list_menu li.search .form-search .group-input input[type="submit"] span.icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 100%; } }

.footer {
  padding: 45px 0;
  background: #191919;
  position: relative;
  z-index: 5; }
  .footer .bgfooter {
    position: absolute;
    width: 624px;
    bottom: 0;
    right: 0;
    z-index: 5; }
    .footer .bgfooter img {
      display: block;
      object-fit: contain;
      height: 100%; }
  .footer .content_footer {
    position: relative;
    z-index: 10; }
  .footer .title_fter {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    position: relative; }
  .footer-top {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 0; }
    @media screen and (min-width: 1080px) {
      .footer-top {
        flex-wrap: nowrap; } }
    .footer-top .item-footer {
      padding: 0 5px;
      flex: 0 0 50%;
      max-width: 50%; }
      @media screen and (min-width: 576px) {
        .footer-top .item-footer {
          flex: 0 0 33.3333%;
          max-width: 33.3333%; } }
      @media screen and (min-width: 768px) {
        .footer-top .item-footer {
          flex: 0 0 20%;
          max-width: 20%; } }
      @media screen and (min-width: 1080px) {
        .footer-top .item-footer {
          flex: 0 0 15%;
          max-width: 15%; } }
      .footer-top .item-footer.address_fter {
        flex: 0 0 100%;
        max-width: 100%; }
        @media screen and (min-width: 1080px) {
          .footer-top .item-footer.address_fter {
            flex: 0 0 25%;
            max-width: 25%; } }
        .footer-top .item-footer.address_fter .txt_address {
          color: #808080;
          line-height: 1.58;
          font-size: 12px;
          margin-top: 5px; }
      .footer-top .item-footer .title_fter {
        margin-bottom: 30px; }
        .footer-top .item-footer .title_fter::after {
          position: absolute;
          bottom: -19px;
          height: 5px;
          width: 37px;
          background: #ED1D24;
          content: '';
          left: 0; }
      .footer-top .item-footer ul {
        list-style-type: none; }
        .footer-top .item-footer ul li {
          padding: 0; }
          .footer-top .item-footer ul li a {
            color: #808080;
            font-size: 12px;
            font-weight: 400;
            display: inline-block; }
  .footer-bottom {
    margin-top: 30px; }
    .footer-bottom .bottom {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 30px 5px; }
    .footer-bottom .socials {
      flex: 0 0 25%;
      max-width: 25%;
      list-style-type: none;
      display: flex; }
      .footer-bottom .socials li {
        margin-right: 20px; }
        .footer-bottom .socials li a {
          display: block;
          height: 17px; }
          .footer-bottom .socials li a img {
            display: block;
            height: 100%;
            width: auto;
            object-fit: contain; }
    .footer-bottom .logo {
      max-width: 160px; }
      @media screen and (min-width: 1280px) {
        .footer-bottom .logo {
          max-width: 223px; } }
    .footer-bottom .copyright {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
      color: #FFFFFF;
      font-size: 12px;
      font-weight: 700;
      padding-right: 40px; }
      @media screen and (min-width: 1080px) {
        .footer-bottom .copyright {
          flex: 1;
          text-align: right; } }
      .footer-bottom .copyright a {
        display: inline-block; }

.breadcrumbs {
  width: 100%;
  background-color: var(--bgSection, #F2F2F2); }
  .breadcrumbs-list {
    display: flex;
    align-items: center;
    list-style-type: none; }
  .breadcrumbs-item {
    display: flex;
    align-items: center;
    line-height: 1.4;
    white-space: nowrap; }
    .breadcrumbs-item > * {
      font-size: 14px;
      font-weight: 400;
      color: #707070;
      margin-right: 8px;
      line-height: 40px; }
    .breadcrumbs-item * {
      transition: .3s all linear; }
    .breadcrumbs-item.active .breadcrumbs-item__link {
      cursor: initial;
      pointer-events: none;
      color: #ED1D24; }
    .breadcrumbs-item:hover .breadcrumbs-item__link {
      color: #ED1D24; }

.life-1 {
  margin-bottom: 80px;
  margin-top: 90px; }
  @media screen and (min-width: 768px) {
    .life-1 {
      margin-top: 0; } }
  .life-1 .item {
    transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .life-1-top {
    position: relative; }
  .life-1__behind {
    transform: translateY(54%);
    display: none; }
    .life-1__behind > * {
      font-family: "Roboto", sans-serif;
      color: #dfdfdf;
      font-size: 52px;
      font-weight: 800; }
      @media screen and (min-width: 768px) {
        .life-1__behind > * {
          font-size: 70px; } }
      @media screen and (min-width: 1080px) {
        .life-1__behind > * {
          font-size: 100px; } }
    @media screen and (min-width: 768px) {
      .life-1__behind {
        display: block; } }
  .life-1__title {
    position: relative;
    z-index: 1;
    margin-top: 40px; }
    @media screen and (min-width: 768px) {
      .life-1__title {
        margin-top: 0; } }
    .life-1__title > * {
      font-family: "Roboto", sans-serif;
      color: #ED1D24;
      font-size: 36px;
      font-weight: 800; }
      @media screen and (min-width: 768px) {
        .life-1__title > * {
          font-size: 48px; } }
      @media screen and (min-width: 1080px) {
        .life-1__title > * {
          font-size: 72px; } }
  .life-1 .wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px; }
    .life-1 .wrapper .navleft {
      display: inline-flex;
      width: 100%;
      height: 100%; }
      @media screen and (min-width: 1080px) {
        .life-1 .wrapper .navleft {
          display: inline-flex;
          width: 24%;
          position: sticky;
          top: 40px;
          height: 100%; } }
      .life-1 .wrapper .navleft > ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        list-style-type: none;
        border: 1px solid #DFDFDF;
        padding: 11px 16px; }
        .life-1 .wrapper .navleft > ul > li {
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          color: #191919;
          font-weight: 800;
          line-height: 40px; }
          @media screen and (min-width: 768px) {
            .life-1 .wrapper .navleft > ul > li {
              font-size: 16px; } }
          @media screen and (min-width: 1080px) {
            .life-1 .wrapper .navleft > ul > li {
              font-size: 18px; } }
          .life-1 .wrapper .navleft > ul > li + li {
            border-top: 1px solid #DFDFDF; }
          .life-1 .wrapper .navleft > ul > li.active {
            color: #ED1D24; }
          .life-1 .wrapper .navleft > ul > li a {
            color: currentColor;
            display: block;
            transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1); }
            .life-1 .wrapper .navleft > ul > li a:hover, .life-1 .wrapper .navleft > ul > li a:focus {
              color: #ED1D24;
              transform: translateX(8px); }
    .life-1 .wrapper .life-1-content {
      flex: 1; }
    .life-1 .wrapper .content_ux {
      font-size: 16px; }
      @media screen and (min-width: 1080px) {
        .life-1 .wrapper .content_ux {
          font-size: 18px; } }
      .life-1 .wrapper .content_ux img {
        display: block; }
  .life-1-content .name {
    display: flex;
    align-items: flex-end; }
    .life-1-content .name > * {
      color: #ED1D24;
      font-weight: 800;
      font-size: 26px; }
      @media screen and (min-width: 768px) {
        .life-1-content .name > * {
          font-size: 28px; } }
      @media screen and (min-width: 1080px) {
        .life-1-content .name > * {
          font-size: 32px; } }
    .life-1-content .name__more {
      flex: 1;
      text-align: right;
      margin-left: 16px; }
      .life-1-content .name__more > * {
        display: inline-block;
        font-size: 15px;
        font-weight: 400;
        text-decoration: underline;
        transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1); }
        .life-1-content .name__more > *:hover {
          transform: translateX(-10px); }
  .life-1-content .item {
    margin-top: 40px; }
    .life-1-content .item-content {
      flex: 1;
      display: flex;
      flex-direction: column; }
    .life-1-content .item__thumb {
      position: relative;
      width: 100%;
      padding-top: 62.5%;
      height: 0;
      overflow: hidden; }
      @media screen and (min-width: 768px) {
        .life-1-content .item__thumb {
          width: 33.33333%;
          padding-top: 27%; } }
      .life-1-content .item__thumb > * {
        transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1); }
      .life-1-content .item__thumb img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%; }
    .life-1-content .item__desc {
      font-size: 15px;
      line-height: 30px;
      font-weight: 400; }
      .life-1-content .item__desc ul {
        list-style-type: none; }
    .life-1-content .item__btn {
      margin-top: 16px; }
      .life-1-content .item__btn a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        max-width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
        color: #ED1D24;
        border: 1px solid currentColor;
        transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1); }
        @media screen and (min-width: 576px) {
          .life-1-content .item__btn a {
            width: 200px; } }
        @media screen and (min-width: 1080px) {
          .life-1-content .item__btn a {
            line-height: 40px;
            width: 240px; } }
        .life-1-content .item__btn a:hover, .life-1-content .item__btn a:focus {
          background-color: #ED1D24;
          color: #FFF; }
    .life-1-content .item__name {
      margin-bottom: 12px; }
      .life-1-content .item__name > * {
        color: #000;
        font-weight: 700;
        font-size: 22px; }
        @media screen and (min-width: 1080px) {
          .life-1-content .item__name > * {
            font-size: 24px; } }
    .life-1-content .item__time {
      padding-left: 24px;
      position: relative;
      margin-bottom: 10px; }
      .life-1-content .item__time::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 10px;
        height: 0;
        border-bottom: 2px solid #ED1D24;
        transform: translateY(-50%); }
      .life-1-content .item__time > * {
        font-size: 12px;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: #AFAFAF; }
    .life-1-content .item__sub {
      margin-top: 20px; }
      .life-1-content .item__sub > * {
        font-size: 18px;
        color: #000;
        font-weight: 700; }
    .life-1-content .item__more > * {
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
      color: #ED1D24;
      text-decoration: underline; }
  .life-1-content .form-jobs .group-input {
    display: flex;
    flex-direction: column; }
    .life-1-content .form-jobs .group-input * {
      transition: 0.3s all cubic-bezier(0.175, 0.885, 0.32, 1.275); }
    @media screen and (min-width: 768px) {
      .life-1-content .form-jobs .group-input {
        flex-direction: row; } }
    .life-1-content .form-jobs .group-input input {
      flex: 1;
      height: 40px;
      border: 1px solid #000;
      color: #000;
      padding: 10px 40px;
      background-color: transparent;
      border-radius: 5px; }
    .life-1-content .form-jobs .group-input button[type="submit"] {
      padding: 10px 40px;
      margin-top: 10px;
      background-color: #ED1D24;
      color: #FFF;
      height: 40px;
      border: 1px solid #ED1D24;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .life-1-content .form-jobs .group-input button[type="submit"] svg path[fill]:not([fill="none"]) {
        fill: #FFF; }
      .life-1-content .form-jobs .group-input button[type="submit"] svg path[stroke]:not([stroke="none"]) {
        stroke: #FFF; }
      @media screen and (min-width: 768px) {
        .life-1-content .form-jobs .group-input button[type="submit"] {
          margin-top: 0px;
          margin-left: 17px;
          padding: 10px 20px;
          background-color: transparent;
          color: #ED1D24; }
          .life-1-content .form-jobs .group-input button[type="submit"] svg path[fill]:not([fill="none"]) {
            fill: #ED1D24; }
          .life-1-content .form-jobs .group-input button[type="submit"] svg path[stroke]:not([stroke="none"]) {
            stroke: #ED1D24; } }
      .life-1-content .form-jobs .group-input button[type="submit"] svg {
        margin-right: 12px; }
      .life-1-content .form-jobs .group-input button[type="submit"]:hover, .life-1-content .form-jobs .group-input button[type="submit"]:focus {
        background-color: #ED1D24 !important;
        color: #FFF !important; }
        .life-1-content .form-jobs .group-input button[type="submit"]:hover svg, .life-1-content .form-jobs .group-input button[type="submit"]:focus svg {
          transform: rotate(45deg) !important; }
          .life-1-content .form-jobs .group-input button[type="submit"]:hover svg path[fill]:not([fill="none"]), .life-1-content .form-jobs .group-input button[type="submit"]:focus svg path[fill]:not([fill="none"]) {
            fill: #FFF !important; }
          .life-1-content .form-jobs .group-input button[type="submit"]:hover svg path[stroke]:not([stroke="none"]), .life-1-content .form-jobs .group-input button[type="submit"]:focus svg path[stroke]:not([stroke="none"]) {
            stroke: #FFF !important; }
  .life-1-content > *:not(:first-child) {
    margin-top: 40px; }
    @media screen and (min-width: 1080px) {
      .life-1-content > *:not(:first-child) {
        margin-top: 60px; } }
  .life-1-content .post-top .item {
    display: flex;
    align-items: stretch;
    gap: 30px;
    flex-direction: column; }
    @media screen and (min-width: 768px) {
      .life-1-content .post-top .item {
        flex-direction: row; }
        .life-1-content .post-top .item-content {
          display: flex;
          flex-direction: column;
          order: 0; }
          .life-1-content .post-top .item-content .item__desc {
            flex: 1; }
        .life-1-content .post-top .item__thumb {
          order: 1; } }
  .life-1-content .post-culture .item {
    display: flex;
    align-items: stretch;
    gap: 30px;
    flex-direction: column; }
    @media screen and (min-width: 768px) {
      .life-1-content .post-culture .item {
        flex-direction: row; }
        .life-1-content .post-culture .item-content {
          display: flex;
          flex-direction: column;
          order: 0; }
          .life-1-content .post-culture .item-content .item__desc {
            flex: 1; }
        .life-1-content .post-culture .item__thumb {
          order: 1;
          width: 100%;
          padding-top: 62%; } }
    @media screen and (min-width: 768px) and (min-width: 768px) {
      .life-1-content .post-culture .item__thumb {
        width: 34.3%;
        padding-top: 21.8%; } }
    @media screen and (min-width: 768px) {
        .life-1-content .post-culture .item:nth-of-type(2n + 1) .item-content {
          order: 1; }
        .life-1-content .post-culture .item:nth-of-type(2n + 1) .item__thumb {
          order: 0; } }
    @media screen and (min-width: 1080px) {
      .life-1-content .post-culture .item {
        gap: 50px; } }
    .life-1-content .post-culture .item__btn {
      margin-top: 40px;
      display: flex;
      justify-content: center; }
    .life-1-content .post-culture .item:not(:first-child) {
      margin-top: 40px; }
    .life-1-content .post-culture .item:hover .item__thumb > * {
      transform: scale(1.2); }
    .life-1-content .post-culture .item:hover .item__name > * {
      color: #ED1D24; }
  .life-1-content .post-work .item {
    display: flex;
    align-items: stretch;
    gap: 20px;
    flex-direction: column; }
    @media screen and (min-width: 768px) {
      .life-1-content .post-work .item {
        gap: 40px;
        flex-direction: row; }
        .life-1-content .post-work .item__desc {
          flex: 1;
          color: #AFAFAF; }
        .life-1-content .post-work .item__btn {
          margin-top: 10px; } }
    .life-1-content .post-work .item:hover .item__name > * {
      color: #ED1D24; }
    .life-1-content .post-work .item:hover .item__thumb > * {
      transform: scale(1.2); }
  .life-1-content .post-jobs .item__name {
    width: 100%; }
    @media screen and (min-width: 1080px) {
      .life-1-content .post-jobs .item__name {
        width: 60%; } }
  .life-1-content .post-jobs .item__desc {
    margin-top: 10px;
    color: #808080; }
  .life-1-content .post-jobs .item__more {
    margin-top: 10px; }
  .life-1-content .post-jobs .item:hover .item__name > * {
    color: #ED1D24; }
  .life-1-content .post-jobs .item__btn {
    margin-top: 40px;
    display: flex;
    justify-content: center; }
  .life-1-content .jobs-detail .item {
    line-height: 1.6; }
    .life-1-content .jobs-detail .item h3 {
      margin-bottom: 20px; }
  .life-1.sub-detail .post-culture .item {
    gap: 34px; }
    .life-1.sub-detail .post-culture .item__thumb {
      width: 100%;
      padding-top: 62%; }
      @media screen and (min-width: 768px) {
        .life-1.sub-detail .post-culture .item__thumb {
          width: 33.33333%;
          padding-top: 30%; } }
    .life-1.sub-detail .post-culture .item-content {
      padding-top: 20px; }
