@mixin family--primary {
  font-family: map-get($family, primary), sans-serif;
}
@mixin family--secondary {
  font-family: map-get($family, secondary), sans-serif;
}
// @mixin family--third {
//   font-family: map-get($family, third), sans-serif;
// }

@mixin plc($i) {
  display: -webkit-box;
  -webkit-line-clamp: #{$i};
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin uppercase{
  text-transform: uppercase;
}
@mixin italic{
  font-style: italic;
}


////////////////////////////////////////////////
//////////// FONT WEIGHT //////////////////////

@mixin thin($important: false){
  @if ($important){
    font-weight: 100 !important;
  }
  @else{
    font-weight: 100;
  }
}
@mixin extra-light($important: false){
  @if ($important){
    font-weight: 200 !important;
  }@else{
    font-weight: 200;
  }
}
@mixin light($important: false){
  @if ($important){
    font-weight: 300 !important;
  }@else{
    font-weight: 300;
  }
}
@mixin regular($important: false){
  @if ($important){
    font-weight: 400 !important;
  }@else{
    font-weight: 400;
  }
}
@mixin normal($important: false){
  @if ($important){
    font-weight: 400 !important;
  }@else{
    font-weight: 400;
  }
}
@mixin medium($important: false){
  @if ($important){
    font-weight: 500 !important;
  }@else{
    font-weight: 500;
  }
}
@mixin semi-bold($important: false){
  @if ($important){
    font-weight: 600 !important;
  }@else{
    font-weight: 600;
  }
}
@mixin bold($important: false){
  @if ($important){
    font-weight: 700 !important;
  }@else{
    font-weight: 700;
  }
}
@mixin extra-bold($important: false){
  @if ($important){
    font-weight: 800 !important;
  }@else{
    font-weight: 800;
  }
}
@mixin ultra-bold($important: false){
  font-weight: 900;
}

//////////// FONT WEIGHT ///////////////////////
////////////////////////////////////////////////



////////////////////////////////////////////////
//////////// Shadow ////////////////////////

@mixin shadow($sd){
  box-shadow: map-get($shadow, #{$sd});
}

//////////// Shadow ////////////////////////
////////////////////////////////////////////////


////////////////////////////////////////////////
//////////// Gradient ////////////////////////

@mixin gradient($gr)
{
  background-image: $gr;
}

//////////// Gradient ////////////////////////
////////////////////////////////////////////////

////////////////////////////////////////////////
//////////// color ////////////////////////

@mixin txt($txt)
{
  color: map-get($color, #{$txt});
}
//////////// color ////////////////////////
////////////////////////////////////////////////

////////////////////////////////////////////////
//////////// background ////////////////////////

@mixin bg($bg)
{
  background-color: map-get($color, #{$bg});
}

// @include txt(main);
//////////// background ////////////////////////
////////////////////////////////////////////////



