.breadcrumbs {
    width: 100%;
    background-color: var(--bgSection, #F2F2F2);


    &-list {        
        display: flex;
        align-items: center;
        list-style-type: none;

        // overflow: auto;
    }
    
    &-item {
        display: flex;
        align-items: center;
        line-height: 1.4;
        white-space: nowrap;

        & > * {
            font-size: 14px;
            @include regular;
            color: #707070;
            margin-right: 8px;
            line-height: 40px;
        }

        *{
            transition: .3s all linear;
        }
        
        &.active {
            .breadcrumbs-item__link {
                cursor: initial;
                pointer-events: none;
                color: #ED1D24;
            }
        }

        &:hover{
            .breadcrumbs-item__link {
                color: #ED1D24;
            }
        }
    }
}
