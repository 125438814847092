.footer{
    padding: 45px 0;
    background: #191919;
    position: relative;
    z-index: 5;

    .bgfooter{
        position: absolute;
        width: 624px;
        bottom: 0;
        right: 0;
        z-index: 5;
        
        img{
            display: block;
            object-fit: contain;
            height: 100%;
        }
    }

    .content_footer{
        position: relative;
        z-index: 10;

    }

    
    .title_fter{
        text-transform: uppercase;
        font-size: 14px;
        @include bold;
        color: #fff;
        position: relative;
    }

    &-top{
        display: flex;
        flex-wrap: wrap;
        gap: 40px 0;

        @include rpmin(lg){
            flex-wrap: nowrap;
        }
        .item-footer{
            padding: 0 5px;
            flex: 0 0 50%;
            max-width: 50%;

            @include rpmin(sm){
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }
            
            @include rpmin(md){
                flex: 0 0 20%;
                max-width: 20%;
            }

            @include rpmin(lg){
                flex: 0 0 15%;
                max-width: 15%;
            }

            &.address_fter{
                flex: 0 0 100%;
                max-width: 100%;
                
                @include rpmin(lg){
                    flex: 0 0 25%;
                    max-width: 25%;
                }

                .txt_address{
                    color: #808080;
                    line-height: 1.58;
                    font-size: 12px;
                    margin-top: 5px;
                }
            }

            .title_fter{
                margin-bottom: 30px;

                &::after{
                    position: absolute;
                    bottom: -19px;
                    height: 5px;
                    width: 37px;
                    background: #ED1D24;
                    content: '';
                    left: 0;
                }
            }

            ul{
                list-style-type: none;
                li{
                    padding: 0;

                    a{
                        color: #808080;
                        font-size: 12px;
                        @include regular;
                        display: inline-block;
                    }
                }
            }
        }
    }

    &-bottom{
        margin-top: 30px;

        .bottom{
            margin-top: 30px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            flex-wrap: wrap;

            gap: 30px 5px;
        }

        .socials{
            flex: 0 0 25%;
            max-width: 25%;

            list-style-type: none;
            display: flex;
            li{
                margin-right: 20px;
                a{
                    display: block;
                    height: 17px;
                    img{
                        display: block;
                        height: 100%;
                        width: auto;
                        object-fit: contain;
                    }
                }
            }
        }

        .logo{
            max-width: 160px;
            
            @include rpmin(xl){
                max-width: 223px;
            }
        }

        .copyright{
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;

            @include rpmin(lg){
                flex: 1;
                text-align: right;
            }

            color: #FFFFFF;
            font-size: 12px;
            @include bold;
            padding-right: 40px;

            a{
                display: inline-block;
            }
        }

    }
}

// .footer{
//     background: linear-gradient(260deg, #BDD4DC 4.18%, #C7DEDB 57.41%, #EBE4D4 91.34%);

//     &-wrapper{
//         padding: 60px 0;
//         display: flex;
//         justify-content: space-between;

//         border-bottom: 1px solid #000;
//     }
//     &-item{
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//     }
//     .item, .nav{
//         // font-size: 16px;
//         @include desc(16px);
//         @include semi-bold;
//         @include family--third;
//         line-height: 140%;
//         color: #000;
//         display: block;
//     }
//     .logo{
//         width: 167px;
//         height: 167px;
//         border-radius: 100%;
//         object-fit: contain;

//         margin-left: auto;
//         margin-right: auto;

//     }
//     .nav{
//         list-style-type: none;
//         display: flex;
//         height: 100%;
//         flex-direction: column;
//         justify-content: space-between;

//         li a{
//             display: inline-block;
//             transition: .2s all;
//             &:hover, &:focus{
//                 color: #15A9A6;
//             }
//         }

//         li + li {
//             margin-top: 20px;
//         }
//     }

//     .item + .item{
//         margin-top: 20px;
//     }

//     .item{
//         p:not(:first-child){
//             margin-top: 8px;
//         }
//     }

//     .name{
//         color: #15A9A6;
//     }

//     .iframe{
//         margin-top: 16px;
//         border: 2px solid #fff;
//         display: block;
//         width: 100%;
//         height: 0;
//         padding-top: 40%;
//         position: relative;
        
//         &>*{
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//         }
//     }

//     &-copyright{
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         padding: 30px 0;

//         .item + .item{
//             margin-top: 20px;
//         }

//         .item{
//             p{
//                 a{
//                     display: inline-block;
//                     color: currentColor;
//                 }
//             }
//         }

//         .socials{
//             margin-top: 20px;
//             a + a{
//                 margin-left: 20px;
//             }
//         }

//         flex-direction: column;
//         .item, .socials{
//             flex: 0 0 100%;
//             width: 100%;
//         }
//         @include rpmin(md){
//             flex-direction: row;
            
//             .item, .socials{
//                 flex: initial;
//                 width: auto;
//             }

//             .item + .item{
//                 margin-top: 0;
//             }

//             .socials{
//                 margin-top: 0px;
//             }
//         }

//         margin-bottom: 70px;
//         @include rpmin(lg){
//             margin-bottom: 0;
//         }
//     }
// }