@mixin rpSize($lg, $md, $default){
    font-size: $default;

    @include rpmin(md){
        font-size: $md;
    }

    @include rpmin(lg){
        font-size: $lg;
    }
}

@mixin rpSpace($space, $lg, $md, $default){
    #{$space}: $default;

    @include rpmin(md){
        #{$space}: $md;
    }

    @include rpmin(lg){
        #{$space}: $lg;
    }
}

@mixin rpSpaceSection($space, $lg, $md, $default){
    #{$space}: $default 0;

    @include rpmin(md){
        #{$space}: $md 0;
    }

    @include rpmin(lg){
        #{$space}: $lg 0;
    }
}

@mixin title($val){
    @if ($val == 100px){
        @include rpSize($val, 70px, 52px);
    }
    @if ($val == 72px){
        @include rpSize($val, 48px, 36px);
    }
    @if ($val == 32px){
        @include rpSize($val, 28px, 26px);
    }
    @if ($val == 18px){
        @include rpSize($val, 16px, 16px);
    }

    @if ($val == 90px){
        @include rpSize($val, 60px, 24px);
    }
    @if ($val == 80px){
        @include rpSize($val, 56px, 30px);
    }
    @if ($val == 64px){
        @include rpSize($val, 48px, 48px);
    }
    @if ($val == 36px){
        @include rpSize($val, 28px, 28px);
    }
    @if ($val == 28px){
        @include rpSize($val, 26px, 24px);
    }
    @if ($val == 24px){
        @include rpSize($val, 20px, 20px);
    }
    @if ($val == 20px){
        @include rpSize($val, 18px, 18px);
    }

}
@mixin desc($val){
    @if ($val == 60px){
        @include rpSize($val, 48px, 32px);
    }
    @if ($val == 32px){
        @include rpSize($val, 24px, 10px);
    }
    @if ($val == 24px){
        @include rpSize($val, 20px, 16px);
    }
    @if ($val == 22px){
        @include rpSize($val, 18px, 12px);
    }
    @if ($val == 20px){
        @include rpSize($val, 18px, 12px);
    }
    @if ($val == 18px){
        @include rpSize($val, 14px, 12px);
    }
    @if ($val == 16px){
        @include rpSize($val, 14px, 14px);
    }
}
@mixin more($val){
    @if ($val == 18px){
        @include rpSize($val, 14px, 12px);
    }
}

@mixin space($key, $val){
    @if ($val == 60px){
        @include rpSpace($key, $val, 40px, 40px);
    }
    @if ($val == 80px){
        @include rpSpace($key, $val, 60px, 48px);
    }
    @if ($val == 120px){
        @include rpSpace($key, $val, 80px, 60px);
    }
    @if ($val == 150px){
        @include rpSpace($key, $val, 100px, 70px);
    }
}

@mixin space-section($key, $val){
    @if ($val == 60px){
        @include rpSpaceSection($key, $val, 40px, 40px);
    }
    @if ($val == 80px){
        @include rpSpaceSection($key, $val, 60px, 60px);
    }
    @if ($val == 120px){
        @include rpSpaceSection($key, $val, 80px, 60px);
    }
    @if ($val == 150px){
        @include rpSpaceSection($key, $val, 100px, 70px);
    }
}