header {
    background: #191919;
    height: 90px;
    z-index: 12;
    
    @include rpmax(xl){
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

        .mobile-toggle{
            position: absolute;
            bottom: 25px;
            right: 20px;
            display: block;

            width: 40px;
            height: 30px;

            .line{
                position: absolute;
                top: 0;
                display: block;
                content: '';
                width: 20px;
                right: 10px;
                height: 3px;
                background: #ED1D24;
                transition: all 0.25s ease-in-out;

                &:nth-of-type(2){
                    top: 8px;
                }
                &:nth-of-type(3){
                    top: 16px;
                }
            }

            &.showed{
                .line:first-child{
                    opacity: 0;
                }
                .line:nth-of-type(2){
                    transform: rotate(45deg) translate(6px, 0);
                }
                .line:nth-of-type(3){
                    transform: rotate(-45deg) translate(5px, 0);
                }

                & ~ .content_menu{
                    left: 0;
                    visibility: visible;
                }
            }
        }
    }
    
    @include rpmin(xl){
        position: relative;

        .mobile-toggle{
            display: none;
        }
    }

    nav{
        display: flex;
        height: 100%;
    }

    .logo{
        padding: 12px 0;
        display: flex;
        align-items: center;
        height: 100%;

        flex: 0 0 160px;
        width: 160px;
        
        @include rpmin(xl){
            flex: 0 0 224px;
            width: 224px;
        }

        a{
            display: inline-block;
            img{
                display: block;
                height: 100%;
            }
        }
    }

    .content_menu{
        @include rpmin(xl){
            flex: 1;
            margin-left: 20px;
    
            .list_menu{
                list-style-type: none;
                display: flex;
                justify-content: space-between;
                height: 100%;
                align-items: flex-end;
    
                li{
                    position: relative;
                    padding-bottom: 15px;
    
                    a{
                        color: #fff;
                        background: #353535;
                        font-size: 14px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
    
                        height: 60px;
                        padding: 0 16px;
    
                        @include bold;
                        text-transform: uppercase;
                        white-space: nowrap;
    
                        &:hover{
                            background: #ED1D24;
                        }
                    }
    
                    &:hover{
                        .content_submenu{
                            display: block;
                        }
                    }
    
                    .content_submenu{
                        display: none;
            
                        position: absolute;
                        top: 100%;
                        left: 0;
            
                        background: #191919;
                        padding: 7px 15px;
            
                        a{
                            color: #fff;
                            font-size: 15px;
                            padding: 12px 0;
                            @include bold;
                            
                            background-color: transparent;
                            height: auto;
                            text-transform: initial;
        
                            white-space: nowrap;
        
                            &:hover{
                                background-color: transparent;
                                color: #ED1D24;
                            }
                        }
        
                        .name{
                            &:not(:first-child){
                                border-top: #353535 solid 2px;
                            }
                        }
                    }
                }
    
                li + li{
                    margin-left: 3px;
                }
    
                li.focus{
                    a{
                        background: #ED1D24;
                    }
                }
    
                li.wrap{
                    a{
                        white-space: initial;
                    }
                }
    
                li.product, li.search{
                    position: static;
    
                    .content_submenu{
                        width: 100vw;
    
                        .name{
                            a{
                                color: #ED1D24;
                                font-size: 15px;
                                padding: 12px 0;
                            }
                        }
    
                        ul{
                            list-style-type: none;
                            li{
                                padding-bottom: 0;
                            }
                            a{
                                font-size: 13px;
                                @include bold;
                                color: #DFDFDF;
                                padding: 0;
                            }
                        }
    
                        .item{
                            &>li{
                                padding: 10px 0;
                                border-bottom: solid 1px #353535;
    
                                position: relative;
    
                                &.active{
                                    .item-sub{
                                        margin-top: 15px;
                                        li, li>a{
                                            opacity: 1;
                                            height: auto;
                                            visibility: visible;
                                            transform: scaleY(1);
                                        }
    
                                        &::before{
                                            opacity: 0;
                                            visibility: hidden;
                                        }
                                    }
                                }
                            }
                            &-sub{
                                margin-top: 0;
                                
                                li{
                                    opacity: 0;
                                    height: 0;
                                    visibility: hidden;
                                    transform: scaleY(0);
    
                                    transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
                                }
                                li>a{
                                    @include regular;
                                    padding: 4px 0;
                                    transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
    
                                    transform: scaleY(0);
                                    opacity: 0;
                                    height: 0;
                                    visibility: hidden;
                                }
    
                                &::after, &::before{
                                    content: "";
                                    position: absolute;
                                    top: 22px;
                                    right: 10px;
                                    width: 10px;
                                    background-color: red;
                                    border-bottom: 2px solid #808080;
                                }
    
                                &::before{
                                    content: "";
                                    position: absolute;
                                    top: 22px;
                                    right: 10px;
                                    width: 10px;
                                    background-color: red;
                                    border-bottom: 2px solid #808080;
                                    transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
                                    transform: rotate(-90deg);
                                }
                            }
                        }
                    }
                    .form-search{
                        width: 100%;
                        // display: block;
                        background: #F2F2F2;
                        padding: 0;
    
                        .group-input{
                            width: 100%;
                            position: relative;
    
                            input[type="text"]{
                                width: 100%;
                                height: 60px;
                                @include regular;
                                color: #808080;
                                background: none;
                                outline: none;
                                border: none;
                                font-size: 16px;
                            }
    
                            button[type="submit"], input[type="submit"]{
                                position: absolute;
                                right: 0;
                                top: 0;
                                width: 112px;
                                height: 60px;
                                border: none;
                                outline: none;
                                background: #ED1D24;
                                font-size: 16px;
                                @include bold;
                                color: #fff;
                            }
                        }
                    }
                }

                li.search{
                    .icon{
                        display: none;
                    }
                }
            }
        }

        @include rpmax(xl){
            visibility: hidden;

            position: absolute;
            top: 90px;
            left: -100%;
            width: 100vw;
            height: calc(100vh - 90px);
    
            overflow-x: hidden;
            overflow-y: auto;
    
            transition: all 500ms;
            background: #353535;
            .list_menu{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                list-style-type: none;
    
                &>li{
                    .content_submenu{
                        // display: none;
                        .banner_adv{
                            display: none;
                        }
    
                        &>*{
                            display: none;
                        }
                    }
                    position: relative;
                    width: 100%;
                    padding: 0 2vw;
                    border-bottom: solid 1px #484848;
    
                    &.active{
                        .content_submenu{
                            &>*{
                                display: block;
                            }
    
                            &::after{
                                transform: rotate(90deg);
                                
                            }
                        }
                    }
                    
                    .content_submenu{
                        background: #191919;
                        margin: 0 -2vw;
                        padding: 10px 2vw;
    
                        &::after{
                            content: "\203A";
                            position: absolute;
                            top: 0px;
                            right: 2vw;
                            font-size: 22px;
                            line-height: 60px;
                            color: #FFF;
    
                            transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
                        }
                        .name{
                            margin: 10px 0;
                            color: #ED1D24;
                            font-size: 15px;
                            border-bottom: none;
                            @include bold;
                            padding: 12px 0;
    
                            a{
                                color: #ED1D24;
                                font-size: 15px;
                                padding: 12px 0;
                            }
                        }
    
                        ul{
                            list-style-type: none;
                            li{
                                padding-bottom: 0;
                            }
                            a{
                                font-size: 13px;
                                @include bold;
                                color: #DFDFDF;
                                padding: 0;
                            }
                        }
    
                        .item{
                            &>li{
                                padding: 10px 10px;
                                border-bottom: solid 1px #909090;
    
                                position: relative;
                                background-color: #353535;
    
                                &.active{
                                    .item-sub{
                                        margin-top: 15px;
    
                                        li, li>a{
                                            opacity: 1;
                                            height: auto;
                                            visibility: visible;
                                            transform: rotateX(0deg);
                                        }
                                        // li>a{
                                        //     clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                                        // }
    
                                        &::before{
                                            opacity: 0;
                                            visibility: hidden;
                                        }
                                    }
                                }
                            }
                            &-sub{
                                padding-left: 10px;
                                
    
                                li, li>a{
                                    opacity: 0;
                                    height: 0;
                                    visibility: hidden;
                                    transform: rotateX(90deg);
                                    transition: .3s all linear;
                                }
                                
                                // li>a{
                                //     transition: .1s all cubic-bezier(0.165, 0.84, 0.44, 1);
                                //     transition-delay: .3s;
                                //     clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
                                // }
    
                                li>a{
                                    @include regular;
                                    padding: 4px 0;
                                }
    
                                &::after, &::before{
                                    content: "";
                                    position: absolute;
                                    top: 22px;
                                    right: 10px;
                                    width: 10px;
                                    border-bottom: 2px solid #808080;
                                }
    
                                &::before{
                                    content: "";
                                    position: absolute;
                                    top: 22px;
                                    right: 10px;
                                    width: 10px;
                                    border-bottom: 2px solid #808080;
                                    transition: .3s all cubic-bezier(0.165, 0.84, 0.44, 1);
                                    transform: rotate(-90deg);
                                }
                            }
                        }
                    }
    
                    &>a{
                        color: #fff;
                        background: transparent;
                        font-size: 14px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
    
                        height: 60px;
    
                        @include bold;
                        text-transform: uppercase;
                        white-space: nowrap;
                    }
                }
    
                li.search{
                    &>a{
                        display: none;
                    }
                    .content_submenu{
                        &>*{
                            display: block !important;
                        }
                        &::after{
                            content: none;
                        }
                    }

                    .form-search{
                        background-color: #353535;

                        .group-input{
                            display: flex;

                            input{
                                line-height: 60px;
                                text-align: center;
                                order: 2;
                                flex: 1;

                                font-size: 14px;
                                @include bold;
                                color: rgba(255, 255, 255, 1);
                                text-transform: uppercase;
                                letter-spacing: 1px;

                                background-color: transparent;
                                outline: none;
                                border: 1px solid rgba(237, 29, 36, 1);
                            
                                &::-webkit-input-placeholder { /* Edge */
                                    color: rgba(255, 255, 255, 1);
                                    text-transform: uppercase;
                                }
                                  
                                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                                    color: rgba(255, 255, 255, 1);
                                    text-transform: uppercase;
                                }
                                
                                &::placeholder {
                                    color: rgba(255, 255, 255, 1);
                                    text-transform: uppercase;
                                }
                            }

                            button[type="submit"], input[type="submit"]{
                                order: 1;
                                line-height: 60px;
                                width: 60px;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                background-color: #ed1d24;
                                outline: none;
                                border: none;

                                span{
                                    display: none;
                                    &.icon{
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}




// .header{
//     position: fixed;
//     top: 0;
//     width: 100%;
//     z-index: 999;
//     display: block;
//     color: white;
//     transition: .16s all ease-in-out;

//     *{
//         transition: .16s all ease-in-out;
//     }

//     &-wrapper{
//         position: relative;
//     }

//     &::after{
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         background-color: white;
//         z-index: -1;
//         transition: .3s all ease-in-out;
//         visibility: hidden;
//         clip-path: circle(0% at 50% 80%);
//     }

//     &-left{
//         position: relative;
//         z-index: 2;
//         height: 100%;
//     } 
//     &-right{
//         position: relative;
//         z-index: 1;
//         height: 100%;
//     }

//     &-left, &-right, &-center{
//         padding-top: 8px;
//         @include rpmin(md){
//             padding-top: 18px;
//         }
//         @include rpmin(lg){
//             padding-top: 0px;
//         }
//     }

//     &-left{
//         width: 100%;
//         justify-content: flex-start;
//         @include rpmin(lg){
//             justify-content: space-between;
//         }
//     }

//     &-right{
//         width: 100%;
//         justify-content: flex-end;
//         @include rpmin(lg){
//             justify-content: space-between;
//         }

//         .header-nav{
//             width: auto;
//         }
//     }

//     &-left, &-right, .left,.right{
//         display: flex;
//         align-items: center;

//         &>*{
//             @include family--third;
//             font-size: 14px;
//             @include semi-bold;
//             letter-spacing: 1.54px;
//             letter-spacing: 11%;
//         }
//     }

//     &-center{
//         // padding-top: 13px;

//         display: flex;
//         flex-direction: column;
//         align-items: center;

//         svg{
//             display: block;
//             position: absolute;
//             // top: calc(100% - 2px);
//             // top: calc(100% - 14px);
//             top: calc(100% - 14px);
//             @include rpmin(lg){
//                 top: calc(100% - 6px);
//             }
//             left: 50%;
//             transform: translateX(-50%);
//             // width: 100%;
//         }
//     }

//     .right{
//         justify-content: flex-end;
//     }

//     &-center{
//         justify-content: center;
//     }

//     &-logo{
//         width: 70px;
//         height: 70px;
//         transform: translateY(0px);
//         position: relative;
        
//         @include rpmin(lg){
//             width: 120px;
//             height: 120px;
//             transform: translateY(20px);
//         }

//         img{
//             display: block;
//             width: 100%;
//             height: 100%;
//             object-fit: contain;
//         }

//         &::after{
//             content: "";
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             height: 140%;
//             width: 140%;

//             @include rpmin(lg){
//                 height: 130%;
//                 width: 130%;
//             }

//             // height: 100%;
//             border-radius: 100%;
//             background-color: white;
//             z-index: -2;
//             transition: .3s all ease-in-out;
//             transform: translate(-50%, -200%);
//             visibility: hidden;
//         }
//     }

//     &-nav{
//         // display: flex;
//         align-items: center;
//         list-style-type: none;

//         width: 100%;
//         justify-content: space-between;

//         display: none;
//         @include rpmin(lg){
//             display: flex;
//         }

//         li a{
//             @include family--third;
//             font-size: 14px;
//             @include semi-bold;
//             letter-spacing: 1.54px;
//             letter-spacing: 11%;
//             color: #FFF;
//             text-transform: uppercase;

//             display: block;
//             background: linear-gradient(to right, #BDD4DC 4.18%, #C7DEDB 57.41%, #EBE4D4 91.34%);
//             background-clip: text !important;
//             -webkit-background-clip: text !important;
//             -webkit-text-fill-color: transparent !important;

//             transition: .2s all;
//             transform-origin: right center;

//             &:hover , &:focus{
//                 color: #15A9A6;
//                 background:transparent;
//                 background-clip: initial !important;
//                 -webkit-background-clip: initial !important;
//                 -webkit-text-fill-color: initial !important;
//             }
//         }
//         li + li{
//             // margin-left: 2vw;
//         }

//         li.active{
//             a{
//                 color: #15A9A6;
//                 background:transparent;
//                 background-clip: initial !important;
//                 -webkit-background-clip: initial !important;
//                 -webkit-text-fill-color: initial !important;
//             }
//         }
//     }

//     &__accounts{
//         display: none;
//         @include rpmin(lg){
//             display: block;

//             &>a{
//                 display: inline-block;
//                 border-radius: 50%;
            
//                 &:hover, &:focus, &.active{
//                     background-color: #15A9A6;
//                 }
//             }
//         }
//         transition: .16s all ease-in-out;
//         *{
//             transition: .16s all ease-in-out;
//         }

//         position: relative;
//         .navsub{
//             position: absolute;
//             top: 100%;
//             left: 50%;
//             transform: translate(-50%, 100%);
//             background-color: #FFF;
//             color: #000;
//             margin-top: 16px;
            
//             opacity: 0;
//             visibility: hidden;

//             &.showed{
//                 opacity: 1;
//                 visibility: visible;
//                 transform: translate(-50%, 0);
//             }

//             &>ul{
//                 padding: 4px 0;
//                 list-style-type: none;
//                 position: relative;

//                 &>li{
//                     white-space: nowrap;
                    
//                     a{
//                         padding: 6px 12px;
//                         display: block;

//                         @include family--third;
//                         font-size: 14px;
//                         @include semi-bold;
//                         letter-spacing: 1.54px;
//                         letter-spacing: 11%;

//                         &:hover{
//                             background-color: #15A9A6;
//                             color: #FFF;
//                         }
//                     }
//                 }

//                 &::after{
//                     content: "";
//                     position: absolute;
//                     bottom: 100%;
//                     left: 50%;
//                     transform: translateX(-50%);

//                     width: 0;
//                     height: 0;
//                     border-left: 5px solid transparent;
//                     border-right: 5px solid transparent;
                  
//                     border-bottom: 5px solid #FFF;
//                 }
//             }
//         }
//     }

//     &__languages{
//         display: none;
//         @include rpmin(lg){
//             display: block;
//         }

//         .languages-opt{
//             @include bold;
//             font-size: 14px;

//             @include family--third;
//             letter-spacing: 1.54px;
//             letter-spacing: 11%;

//             color: #FFF;

//             display: block;
//             background: linear-gradient(to right, #BDD4DC 4.18%, #C7DEDB 57.41%, #EBE4D4 91.34%);
//             background-clip: text !important;
//             -webkit-text-fill-color: transparent;

//             transition: .2s all;
//             transform-origin: right center;

//             &:hover, &:focus{
//                 color: #15A9A6;
//                 background:transparent;
//                 background-clip: initial !important;
//                 -webkit-background-clip: initial !important;
//                 -webkit-text-fill-color: initial !important;
//             }
//         }
//     }

//     &-book{
//         a{
//             background-color: #15A9A6;
        
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             border-radius: 30px;
    
//             font-size: 8px;
//             padding: 7px 12px;
//             min-width: 80px;
            
//             @include rpmin(lg){
//                 padding: 11px 30px;
//                 border-radius: 30px;
//                 min-width: 180px;
    
//                 font-size: 14px;
//             }
    
//             position: relative;
//             &:before {
//                 content: '';
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 width: 100%;
//                 height: 100%;
//                 border-radius: 50px;
//                 background: currentColor;
//                 z-index: -1;
//                 opacity: 0;
//                 -webkit-transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
//                 transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
//                 -webkit-transform: scale(0.2);
//                         transform: scale(0.2);
//               }
    
//             &:hover, &:focus{
//                 transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
//                 transform: scale(1);
                
//                 &::before{
//                     opacity: 0.15;
//                     -webkit-transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
//                     transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
//                     -webkit-transform: scale(1);
//                             transform: scale(1);
//                 }
//             }
//         }

//     }

//     &-mb{
//         &__primary{
//             width: 24px;
//             height: 24px;
//             display: flex;
//             flex-direction: column;
//             justify-content: space-between;

//             @include rpmin(lg){
//                 display: none;
//             }

//             padding: 4px 2px;
//             // background-color: gray;

//             .line{
//                 width: 100%;
//                 height: 2px;
//                 border-radius: 2px;
                                
//                 background: linear-gradient(to right, #BDD4DC 4.18%, #C7DEDB 57.41%, #EBE4D4 91.34%);
//             }
//         }

//         &-menu{
//             position: fixed;
//             top: 0;
//             left: 0;
//             width: 100vw;
//             height: 100vh;


//             z-index: 1001;
//             background-color: transparent;

//             &::after{
//                 content: "";
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 width: 100%;
//                 height: 100%;
//                 background-color: #000;
//                 opacity: .5;
//             }
            
//             .content{
//                 position: relative;
//                 z-index: 1;

//                 // width: 50vw;
//                 height: 100vh;
//                 width: 320px;
//                 max-width: 100%;

//                 padding-left: 2vw;
//                 padding-right: 2vw;
    
//                 background-color: white;
//                 padding-top: 38px;
//             }

//             .menu-top{
//                 width: 100%;
//                 height: 70px;

//                 display: flex;
//                 align-items: center;
//             }

//             .close{
//                 width: 24px;
//                 height: 24px;
//                 padding: 4px 2px;
//                 position: relative;

//                 .line{
//                     width: 100%;
//                     border-top: 2px solid #15A9A6;
//                     border-radius: 2px;
//                     position: absolute;
//                     top: 50%;
//                     left: 50%;
//                     transform: translate(-50%, -50%) rotate(45deg);
                    
//                     &:first-child{
//                         transform: translate(-50%, -50%) rotate(-45deg);
//                     }
//                 }
//             }

//             .menu-nav{
//                 list-style-type: none;
//                 margin-top: 40px;


//                 li a{
//                     font-size: 20px;
//                     display: inline-block;
//                     color: #000;
//                     @include family--third;
//                     @include semi-bold;
//                 }

//                 li{
//                     margin-top: 16px;
//                 }
//             }

//             // display: none;
//             clip-path: circle(0px at calc(2vw + 12px) 70px);
//             visibility: hidden;
//             &.show{
//                 visibility: visible;
//                 clip-path: circle(135% at calc(2vw + 12px) 70px);
//             }
//         }

//         &-bottom{
//             position: fixed;
//             bottom: 0;
//             left: 0;
//             width: 100vw;
//             height: 70px;
//             background-color: white;

//             display: block;
//             @include rpmin(lg){
//                 display: none;
//             }

//             &>.container{
//                 height: 100%;
//             }

//             .bottom-nav{
//                 display: flex;
//                 list-style-type: none;
//                 height: 100%;
//                 width: 100%;
//                 background-color: #FFF;

//                 &>*{
//                     flex: 1;
//                     height: 100%;
//                 }

//                 &>li{
//                     --fillCl: #CACBCE;
//                     --fillClHover: #15A9A6;

//                     &>a{
//                         width: 100%;
//                         height: 100%;
//                         padding: 14px 6px;
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         flex-direction: column;
//                         text-align: center;

//                         color: #000;
//                         font-size: 10px;
//                         @include family--third;
//                         @include regular;

//                         span:first-child{
//                             display: inline-block;
//                             padding-bottom: 2px;
//                             margin-bottom: 5px;
//                             border-bottom: 1px solid transparent;
//                         }

//                         span:not(:first-child){
//                             flex: 1;
//                         }

//                         svg{
//                             path{
//                                 &[fill]:not([fill="none"]){
//                                     fill: var(--fillCl);
//                                 }
//                                 &[stroke]:not([stroke="none"]){
//                                     stroke: var(--fillCl);
//                                 }
                                
//                             }
//                         }
//                     }

//                     &.active{
//                         --fillCl: #15A9A6;

//                         a{
//                             span:first-child{
//                                 border-bottom: 1px solid var(--fillCl);
//                             }
//                         }

//                         // span:first-child{
//                         //     border-bottom: 1px solid var();
//                         // }
//                         // svg{
//                         //     path{
//                         //         &[fill]:not([fill="none"]){
//                         //             fill: var(--fillClHover);
//                         //         }
//                         //         &[stroke]:not([stroke="none"]){
//                         //             stroke: var(--fillClHover);
//                         //         }
//                         //     }
//                         // }
//                     }
//                 }


//                 .navsub{
//                     position: absolute;
//                     bottom: 0;
//                     left: 0;
//                     width: 100%;
//                     background-color: #FFF;
//                     color: #000;
//                     z-index: -1;

//                     border-top-left-radius: 30px;
//                     border-top-right-radius: 30px;

//                     box-shadow: 0px -1px 4px rgba(0, 0, 0, .4);

//                     transform: translateY(100%);
//                     transition: .6s all cubic-bezier(0.165, 0.84, 0.44, 1);
                    
//                     &.showed{ 
//                         bottom: 100%;
//                         transform: translateY(0%);
//                     }

//                     .name{
//                         position: relative;
//                         display: flex;
//                         justify-content: center;
//                         padding: 10px 0;

//                         &>*{
//                             color: #000;
//                             @include medium;
//                             @include family--secondary;
//                             font-size: 16px;
//                             text-align: center;
//                         }

//                         &::after{
//                             content: "";
//                             position: absolute;
//                             position: absolute;
//                             width: calc(100% + 4vw);
//                             left: 50%;
//                             bottom: 0;
//                             transform: translate(-50%);
//                             border: 0.91px solid rgba(210, 214, 225, 1);
//                         }
//                     }
//                     ul{
//                         margin: 24px 0;

//                         list-style-type: none;

//                         display: flex;
//                         flex-wrap: wrap;
//                         align-items: stretch;
//                         gap: 20px;

//                         justify-content: flex-start;

//                         // grid-template-columns: repeat(4, 1fr);

//                         @include rpmin(sm){
//                             gap: 20px 30px;
//                         }

//                         &>li{
//                             width: calc(25% - 15px);

//                             @include rpmin(sm){
//                                 width: calc(20% - 24px);                                
//                             }
                            
//                             &>a{
//                                 height: 100%;    
//                                 display: flex;
//                                 flex-direction: column;
//                                 text-align: center;

//                                 img, svg{
//                                     margin: 0 auto 16px auto;
//                                     height: 60px;
                                    
//                                     @include rpmin(sm){
//                                         height: 60px;
//                                         // flex: 1;
//                                         // width: 64%;
//                                         // height: auto;
//                                     }
                                    
//                                     object-fit: contain;
//                                     transition: .3s all cubic-bezier(0.075, 0.82, 0.165, 1);
//                                 }
//                                 span{
//                                     display: block;
//                                 }

//                                 &:hover{
//                                     img, svg{
//                                         transform: scale(1.2);
//                                     }
//                                     span{
//                                         color: #15A9A6;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     .line-pc{
//         display: none;
//         @include rpmin(lg){
//             display: block;
//         }
//     }
//     .line-mb{
//         display: block;
//         @include rpmin(lg){
//             display: none;
//         }
//     }

//     &.scroll{
//         box-shadow: 0 1px 1px rgba(0,0,0,.1);

//         &::after{
//             height: 100%;
//             visibility: visible;
//             clip-path: circle(112% at 50% 90%);
//         }

//         .header-left, .header-right, .header-center{
//             padding-top: 10px;
//             @include rpmin(lg){
//                 padding-top: 0px;
//             }
//         }

//         .header-center{
//             svg{
//                 display: none;
//             }
//         }
//         .header-logo{
//             &::after{
//                 transform: translate(-50%, -50%);
//                 visibility: visible;

//                 // box-shadow: 0 2px 2px 2px rgba(0,0,0,.1);
//             }
//         }

//         .header-nav{
//             li a{
//                 color: #15A9A6;
//                 background: transparent;
//                 background-clip: initial !important;
//                 -webkit-background-clip: initial !important;
//                 -webkit-text-fill-color: initial !important;
//             }

//             li.active{
//                 a{
//                     color: #ECDCBE;
//                 }
//             }

//             li a:hover, li a:focus{
//                 color: #ECDCBE;
//             }
//         }

//         .header__languages{
//             .languages-opt{
//                 color: #15A9A6;
//                 background: transparent;
//                 background-clip: initial !important;
//                 -webkit-background-clip: initial !important;
//                 -webkit-text-fill-color: initial !important;

//                 &:hover, &:focus{
//                     color: #ECDCBE;
//                 }
//             }
//         }

//         .header__accounts{
//             path{
//                 fill: #15A9A6;
//             }
//             circle{
//                 stroke: #15A9A6;
//             }

//             &:hover, &:focus, &.active{
//                 // background-color: #15A9A6;

//                 svg{
//                     fill: #15A9A6;
//                 }

//                 path{
//                     fill: #fff;
//                 }
//                 circle{
//                     stroke: #fff;
//                 }
//             }

//             .navsub{
//                 background-color: #CACBCE;
//                 ul{
//                     &::after{
//                         border-bottom: 5px solid #CACBCE;
//                     }
//                 }
//             }
//         }

//         .header-mb{
//             &__primary{
//                 .line{
//                     background: #15A9A6;
//                 }
//             }

//             &-menu{
//                 clip-path: circle(0px at calc(2vw + 12px) 45px);
//                 &.show{
//                     display: block;
//                     clip-path: circle(135% at calc(2vw + 12px) 45px);
//                 }

//                 .content{
//                     padding-top: 10px;
//                 }
//             }
//         }
//     }
// }