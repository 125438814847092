.ov-hide{
    overflow: hidden;
}
.m-auto{
    margin-left: auto;
    margin-right: auto;
}
.ml-auto{
    margin-left: auto;
}
.mr-auto{
    margin-right: auto;
}



.thin{
    @include thin(true);
}
.extra-light{
    @include extra-light(true);
}
.light{
    @include light(true);
}
.regular{
    @include regular(true);
}
.normal{
    @include normal(true);
}
.medium{
    @include medium(true);
}
.semi-bold{
    @include semi-bold(true);
}
.bold{
    @include bold(true);
}
.extra-bold{
    @include extra-bold(true);
}
.ultra-bold{
    @include ultra-bold(true);
}

.m-c{margin-left:auto;margin-right:auto}

// [class|="fx"]{display:flex}
// [class^="fx-c"]{justify-content:center}
// [class^="fx-l"]{justify-content:flex-start}
// [class^="fx-r"]{justify-content:flex-end}
// .fx-cc,.fx-lc,.fx-rc{align-items:center}
// .fx-cl,.fx-ll,.fx-rl{align-items:flex-start}
// .fx-lr,.fx-cr,.fx-rr{align-items:flex-end}
// .fx-ls,.fx-cs,.fx-rs{align-items:stretch}
// .fx-1{flex:1}
// .fx-col{flex-direction:column}
// .fx-row{flex-direction:row}

.tc{text-align: center}
.tl{text-align: left}
.tr{text-align: right}

.fx{display: flex}
.fx-c{
    justify-content: center;
    align-items: center;
}
.fx-wrap{flex-wrap: wrap}
.fx-col{flex-direction: column}
.fx-row{flex-direction: row}

.jc-c{justify-content: center}
.jc-sb{justify-content: space-between}
.jc-fs{justify-content: flex-start}
.jc-fe{justify-content: flex-end}

.ai-c{align-items: center}
.ai-s{align-items: stretch}
.ai-sb{align-items: space-between}
.ai-fs{align-items: flex-start}
.ai-fe{align-items: flex-end}

@each $key, $size in $minWidth {
    @media (min-width: $size){ 
        .tc-#{$key}{text-align: center}
        .tl-#{$key}{text-align: left}
        .tr-#{$key}{text-align: right}

        .jc-c-#{$key}{justify-content: center}
        .jc-sb-#{$key}{justify-content: space-between}
        .jc-fs-#{$key}{justify-content: flex-start}
        .jc-fe-#{$key}{justify-content: flex-end}

        .ai-c-#{$key}{align-items: center}
        .ai-s-#{$key}{align-items: stretch}

        .ai-sb-#{$key}{align-items: space-between}
        .ai-fs-#{$key}{align-items: flex-start}
        .ai-fe-#{$key}{align-items: flex-end}

        .fx-wrap-#{$key}{flex-wrap: wrap}
        .fx-col-#{$key}{flex-direction: column}
        .fx-row-#{$key}{flex-direction: row}
    }
}

.uppercase{
    text-transform: uppercase;
}
.bold{
    font-weight: bold;
}

.italic{
    font-style: italic;
}

.d-none{
    display: none;
}

.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.animate__bounce {
    animation-name: bounce;
    animation-duration: 1s;
    transform-origin: center bottom;
}

.scrollgsap{
    position: relative;
    transition: top .5s ease,opacity .1s ease;
    top: 150px;
    opacity: 0;

    &.gsap-loaded{
        top: 0;
        opacity: 1;
    }
}

.of-scroll{
    /* width */
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        border-radius: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #EAEAEA;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--color-main);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #5D5D5D;
    }
}

.appear-box{
    animation: appearBox .4s linear;
}

@for $i from 1 through 10{
    .plc-#{$i}{
        display: -webkit-box;
        -webkit-line-clamp: #{$i};
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

@each $key,
$val in $color {
    .cl-#{$key} {
        color: #{$val};
    }
}


