.toast-msg{
    position: fixed;
    bottom: 80px;
    right: 20px;
    z-index: 11111;
    // overflow: hidden;
    
    @include rpmin(lg){
        bottom: 20px;
    }
        list-style-type: none;

    li{
        display: block;
        background-color: #333;
        &.success{
            background-color: #40f467;
        }
        &.error{
            background-color: #D81A1A;
        }
        &>p{
            color: #fff;
            @include family--primary;
            @include medium;
            font-size: 16px;

            
            padding: 6px 8px;
            @include rpmin(lg){
                padding: 8px 12px;
            }
        }

        transform: translateX(calc(100% + 20px));
        transition: 0.6s all cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &.active{
            transform: translateX(0);
        }

        cursor: pointer;

        &>*{
            user-select: none;
            pointer-events: none;
        }
    }

    li + li {
        margin-top: 10px;
    }
}

.share-socials{
    margin-top: 30px;
    
    @include rpmin(lg){
        margin-top: 60px;
    }

    h3{
        @include family--primary;
        font-size: 14px;
        line-height: 21px;
        color: #000;
        @include bold;

    }

    ul{
        margin-top: 24px;

        list-style-type: none;

        display: flex;
        flex-wrap: wrap;

        li>a{
            display: inline-block;
            &>*{
                user-select: none;
                pointer-events: none;
            }
        }

        li + li {
            margin-left: 30px;
        }
    }
}