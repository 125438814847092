.pagination {
    background-color: var(--bgSection, #FFF);

    --wh: 30px;
    --sText: 16px;

    --swArrow: 7px;
    --shArrow: 12px;
    
    @include rpmin(lg){
        --wh: 30px;
        --sText: 16px;

        --swArrow: 6px;
        --shArrow: 10x;
    }

    --bgBtn: transparent;
    --text: #000;
    
    --bgBtnHover: #ED1D24;
    --textHover: #FFF;

    --bgArrow: transparent;
    --textArrow: #000000;

    --bgArrowHover: #ED1D24;
    --textArrowHover: #FFF;

    list-style-type: none;

    li {
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        user-select: none;

        transition: .3s ease-in-out;

        @include family--primary;
            
        height: var(--wh);
        width: var(--wh);
        font-size: var(--sText);
        color: var(--text);
        background-color: var(--bgBtn);

        border: 1px solid var(--text);

        &:not(.active):hover {
            color: var(--textHover);
            background-color: var(--bgBtnHover);

            border: 0px solid transparent;
        }

        // @include size()
        svg {
            width: var(--swArrow);
            height: var(--shArrow);
        }

        &.active {
            cursor: initial;
            color: var(--textHover);
            background-color: var(--bgBtnHover);

            border: 0px solid transparent;
        }
    }

    li.next,
    li.prev {
        background-color: var(--bgArrow);
        width: var(--wh);
        height: var(--wh);
        transition: .3s ease-in-out;

        path{
            &[fill]{
                fill: var(--textArrow);
            }
            &[stroke]{
                stroke: var(--textArrow);
            }
        }

        &:not(.btn-hidden):hover {
            background-color: var(--bgArrowHover);

            path{
                &[fill]{
                    fill: var(--textArrowHover);
                }
                &[stroke]{
                    stroke: var(--textArrowHover);
                }
            }
        }

        &.btn-hidden {
            display: none;
            cursor: initial;
        }
    }

    li.prev.btn-hidden + li{
        margin-left: 0;
    }

    li.prev {
        svg {
            transform: rotate(180deg);
        }
    }

    li.dot{
        user-select: none;
        pointer-events: none;

        background-color: var(--bgBtn);
    }

    li + li {
        margin-left: 10px;
        @include rpmin(lg){
            margin-left: 18px;
        }
    }
}

.pagination{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    margin-top: 40px;
    
    li{
        font-weight: bold;
    }
}